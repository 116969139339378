import { createSlice } from "@reduxjs/toolkit";
import { formatPeriod } from "../../../../utils/common-utils";
import { MONTH_NAMES_SHORT } from "../../../../consts/Constants";

const initialState = {
  complianceYear: null,
  compliancePeriod: "01 Jan - 31 Dec",
  complianceEntity: null,
  complianceFormattedPeriod: null,
  bannerStatus: "due",
  complianceEntityName: null,
  compliancePeriodsList: {},
};
const getComplianceDetails = (incorporation_date) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const incorporationDate = incorporation_date
    ? new Date(incorporation_date)
    : null;
  const incorporationYear = incorporationDate?.getFullYear();

  let complianceYear = currentYear;
  let compliancePeriod = "01 Jan - 31 Dec";
  let bannerStatus = "due";

  // Guard clause for invalid incorporation date
  if (!incorporationDate) {
    return { complianceYear, compliancePeriod, bannerStatus };
  }

  if (currentMonth < 8 && incorporationYear < currentYear) {
    complianceYear = currentYear - 1;
  } else {
    bannerStatus = "start early";
  }

  if (
    incorporationYear === complianceYear ||
    incorporationYear === currentYear
  ) {
    const incorporationMonth = incorporationDate.getMonth();
    const incorporationMonthName = MONTH_NAMES_SHORT[incorporationMonth];
    const incorporationDay = incorporationDate.getDate()?.toString().padStart(2, '0');
    compliancePeriod = `${incorporationDay} ${incorporationMonthName} - 31 Dec`;
  }

  return { complianceYear, compliancePeriod, bannerStatus };
};

const complianceFiltersSlice = createSlice({
  name: "complianceFilters",
  initialState,
  reducers: {
    setComplianceYear(state, action) {
      state.complianceYear = action.payload;

      if (state.complianceEntity) {
        const incorporationDate = new Date(
          state.complianceEntity?.incorporationDate
        );
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const incorporationYear = incorporationDate.getFullYear();
        if (incorporationYear == action.payload) {
          const incorporationMonth = incorporationDate.getMonth();
          const incorporationMonthName = MONTH_NAMES_SHORT[incorporationMonth];
          const incorporationDay = incorporationDate.getDate();
          state.compliancePeriod = `${incorporationDay} ${incorporationMonthName} - 31 Dec`;
          state.complianceFormattedPeriod = formatPeriod(
            action.payload,
            state.compliancePeriod
          );
        } else {
          state.compliancePeriod = "01 Jan - 31 Dec";
          state.complianceFormattedPeriod = formatPeriod(
            action.payload,
            state.compliancePeriod
          );
        }
        if (action.payload == currentYear && currentMonth > 7) {
          state.bannerStatus = "start early";
        } else {
          state.bannerStatus = "due";
        }
      }
    },
    setCompliancePeriod(state, action) {
      state.compliancePeriod = action.payload;
      state.complianceFormattedPeriod = formatPeriod(
        state.complianceYear,
        action.payload
      );
    },
    setComplianceEntity(state, action) {
      state.complianceEntity = action.payload;
      state.complianceEntityName = action.payload?.entityName;
      const { complianceYear, compliancePeriod, bannerStatus } =
        getComplianceDetails(action.payload?.incorporationDate);
      state.complianceYear = complianceYear;
      state.compliancePeriod = compliancePeriod;
      state.complianceFormattedPeriod = formatPeriod(
        complianceYear,
        compliancePeriod
      );
      state.bannerStatus = bannerStatus;
      state.compliancePeriodsList = {};

    },
    resetComplianceFilters(state) {
      state.complianceYear = null;
      state.compliancePeriod = "01 Jan - 31 Dec";
      state.complianceFormattedPeriod = null;
      state.complianceEntity = null;
      state.bannerStatus = "due";
    },
    setComplianceFiltersFromState(state, action) {
      const { complianceYear, compliancePeriod, complianceEntity } = action.payload;
      state.complianceYear = complianceYear;
      state.compliancePeriod = compliancePeriod;
      state.complianceEntity = complianceEntity;
      state.complianceEntityName = complianceEntity?.entityName;
      state.complianceFormattedPeriod = formatPeriod(
        complianceYear,
        compliancePeriod
      );
    },
    setAllCompliancePeriods(state, action) {
      let compliancePeriods = {};
      action.payload?.forEach((period) => {
        const taxYear = String(period.tax_year);
        if (!compliancePeriods[taxYear]) {
          compliancePeriods[taxYear] = [];
        }
        compliancePeriods[taxYear].push(period.filing_period);
      });
      state.compliancePeriodsList = compliancePeriods;
    },
  },
});

export const complianceFiltersActions = complianceFiltersSlice.actions;
export default complianceFiltersSlice.reducer;
