import Dropdown from "../Dropdown/Dropdown";
import styles from "./Filter.module.css";

const Filter = ({ labelTitle, value, dropdownSize, ...props }) => {
  return (
    <div className={styles.filter}>
      <div className={styles.filter_label}>{labelTitle}</div>
      <Dropdown
        dropdownSize={`${styles.dropdown} ${dropdownSize}`}
        headerStyle={`${styles.dropdown_header} ${dropdownSize}`}
        dropdownListSize={styles.dropdown_item}
        arrowStyle={styles.arrow}
        selected={value}
        {...props}
      />
    </div>
  );
};

export default Filter;
