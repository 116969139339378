import { useState } from "react";
import styles from "./EditAuthorizedShares.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import { ERROR_MSG__NUMERICAL_VALUES } from "../../../../../../consts/ErrorMessages";
import Button from "../../../../../UI/Button/Button";
import {
  useUpdateComplianceEntityMutation,
  useLazyGetUpdatedEntityQuery,
} from "../../../apis/complianceApi-slice";
import {
  isNumeric,
  isNotEmpty,
  isGreaterThanValue,
} from "../../../../../../utils/validations";
import useInput from "../../../../../../hooks/use-input";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";

const EditAuthorizedShares = ({ entityType, entityId, onClose }) => {
  const [error, setError] = useState({ isError: false });

  const {
    value: authorizedShares,
    isValid: isAuthorizedSharesValid,
    showError: showAuthorizedSharesError,
    handleChange: handleAuthorizedSharesChange,
    handleBlur: handleAuthorizedSharesBlur,
    handleFocus: handleAuthorizedSharesFocus,
    setShowError: setShowAuthorizedSharesError,
  } = useInput(
    (value) =>
      isNumeric(value) && isNotEmpty(value) && isGreaterThanValue(value, 0)
  );

  const [updateComplianceEntity, { isLoading }] =
    useUpdateComplianceEntityMutation();
  const [getComplianceEntity, { isLoading: isComplianceEntityLoading }] =
    useLazyGetUpdatedEntityQuery();

  const handleSubmit = async () => {
    if (!isAuthorizedSharesValid) {
      setShowAuthorizedSharesError(true);
      return;
    }
    try {
      const payload = {
        entity_id: entityId,
        authorized_shares: Number(authorizedShares),
      };
      const response = await updateComplianceEntity(payload).unwrap();
      await getComplianceEntity({ entity_id: entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <div className={styles.container}>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
      <div className={styles.form_inputs}>
        <EditInputItem
          name="authorizedShares"
          labelTitle={`Authorized Shares`}
          onChange={handleAuthorizedSharesChange}
          onBlur={handleAuthorizedSharesBlur}
          onFocus={handleAuthorizedSharesFocus}
          value={authorizedShares}
          showError={showAuthorizedSharesError}
          errorMessage={"Please enter valid authorized shares"}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={handleSubmit}
        buttonstyle="default-blue"
        isLoading={isLoading}
      >
        Submit
      </Button>
    </div>
  );
};

export default EditAuthorizedShares;
