import SignInUpLandingPage from "../components/Login/SignInUpLandingPage.jsx";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import LoadingComponent from "../components/UI/LoadingIndicator/LoadingComponent.jsx";

const SignUp = () => {
  const { session } = useOutletContext();
  const location = useLocation();
  
  if (session?.isValidSession == null) {
    return <LoadingComponent />;
  }
  return session?.isValidSession ? (
    <Navigate to="/home" state={{ from: location }} replace />
  ) : (
    <SignInUpLandingPage isSignUp={true} />
  );
};
export default SignUp;
