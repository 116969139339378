import { useState } from "react";
import styles from "./EditIssuedShares.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import { ERROR_MSG__NUMERICAL_VALUES } from "../../../../../../consts/ErrorMessages";
import Button from "../../../../../UI/Button/Button";
import {
  useUpdateComplianceEntityMutation,
  useLazyGetUpdatedEntityQuery,
  useLazyGetForeignShareHoldersQuery,
} from "../../../apis/complianceApi-slice";
import {
  isNumeric,
  isNotEmpty,
  isGreaterThanValue,
} from "../../../../../../utils/validations";
import useInput from "../../../../../../hooks/use-input";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";
import { convertISODateToStr } from "../../../../../../utils/common-utils";

const EditIssuedShares = ({ entityType, entityId, onClose, toDate }) => {
  const [error, setError] = useState({ isError: false });

  const {
    value: issuedShares,
    isValid: isIssuedSharesValid,
    showError: showIssuedSharesError,
    handleChange: handleIssuedSharesChange,
    handleBlur: handleIssuedSharesBlur,
    handleFocus: handleIssuedSharesFocus,
    setShowError: setShowIssuedSharesError,
  } = useInput(
    (value) =>
      isNumeric(value) && isNotEmpty(value) && isGreaterThanValue(value, 0)
  );

  const [updateComplianceEntity, { isLoading }] =
    useUpdateComplianceEntityMutation();
  const [getComplianceEntity, { isLoading: isComplianceEntityLoading }] =
    useLazyGetUpdatedEntityQuery();

  const [getForeignShareHolders, { isLoading: isForeignShareHoldersLoading }] =
    useLazyGetForeignShareHoldersQuery();

  const handleSubmit = async () => {
    if (!isIssuedSharesValid) {
      setShowIssuedSharesError(true);
      return;
    }
    try {
      const payload = {
        entity_id: entityId,
        issued_shares: Number(issuedShares),
      };
      const response = await updateComplianceEntity(payload).unwrap();
      await getComplianceEntity({ entity_id: entityId });
      await getForeignShareHolders({ entity_id: entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <div className={styles.container}>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
      <div className={styles.form_inputs}>
        <EditInputItem
          name="issuedShares"
          labelTitle={`Issued Shares * ${
            toDate ? "as on " + convertISODateToStr(toDate) : ""
          }`}
          onChange={handleIssuedSharesChange}
          onBlur={handleIssuedSharesBlur}
          onFocus={handleIssuedSharesFocus}
          value={issuedShares}
          showError={showIssuedSharesError}
          errorMessage={"Please enter valid issued shares"}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={handleSubmit}
        buttonstyle="default-blue"
        isLoading={isLoading || isForeignShareHoldersLoading}
      >
        Submit
      </Button>
    </div>
  );
};

export default EditIssuedShares;
