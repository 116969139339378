export const getAuthToken = () => {
    const token = sessionStorage.getItem("token");
    return token;
};

export const removeAuthToken = () => {
    sessionStorage.removeItem("token");
};

export const getUser =() => {
    const user = sessionStorage.getItem("user");
    return user;
};

export const setUser = (user) => {
    sessionStorage.setItem("user", user);
};

export const removeUser = () => {
    sessionStorage.removeItem("user");
};

export const setStripeRedirectUrls = (successUrl, failureUrl, state = {}) => {
    sessionStorage.setItem("stripeSuccessRedirectUrl", successUrl);
    sessionStorage.setItem("stripeFailureRedirectUrl", failureUrl);
    sessionStorage.setItem("stripeState", JSON.stringify(state));
};

export const getStripeRedirectUrls = () => {
    const successUrl = sessionStorage.getItem("stripeSuccessRedirectUrl");
    const failureUrl = sessionStorage.getItem("stripeFailureRedirectUrl");
    const state = sessionStorage.getItem("stripeState");
    const parsedState = state && state !== "undefined" ? JSON.parse(state) : null;
    return { successUrl, failureUrl, state: parsedState };
};

export const removeStripeRedirectUrls = () => {
    sessionStorage.removeItem("stripeSuccessRedirectUrl");
    sessionStorage.removeItem("stripeFailureRedirectUrl");
    sessionStorage.removeItem("stripeState");
};
