import {
  URL__COMPLIANCE_LIST,
  METHOD_POST,
  METHOD_GET,
  METHOD_PUT,
  URL__CREATE_FOREIGN_SHARE_HOLDER,
  URL__CREATE_FOREIGN_SUBSIDIARY,
  URL__CREATE_FOREIGN_QUALIFICATION,
  URL__UPDATE_COMPLIANCE_ENTITY,
  URL__SET_COUPOUN_CODE,
  URL__GET_ADD_ONS_LIST,
  URL__GET_COMPLIANCE_PACKAGES,
  URL__GET_UPDATED_ENTITY_COMPLIANCE,
  URL__GET_FOREIGN_QUALIFICATIONS,
  URL__CREATE_COMPLIANCE_PAYMENT,
  URL__GET_FOREIGN_SUBSIDIARIES,
  URL_GET_SHARE_HOLDERS,
  URL__GET_SUBSCRIBED_COMPLIANCE_LIST,
  URL__GET_FOREIGN_SHARE_HOLDERS,
  URL__GET_ALL_COMPLIANCE_PERIODS,
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { complianceActions } from "../reducers/compliance-slice";
import { complianceFiltersActions } from "../reducers/complianceFilters-slice";
const complianceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComplianceList: builder.query({
      extraOptions: {
        endpoint: "compliance",
      },

      query: (args) => {
        return {
          url: URL__COMPLIANCE_LIST,
          method: METHOD_GET,
          header: { Accept: "*/*", "content-type": "application/json" },
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setComplianceListData(data));
        } catch (error) {}
      },
    }),
    createForeignShareHolder: builder.mutation({
      query: (args) => {
        return {
          url: URL__CREATE_FOREIGN_SHARE_HOLDER,
          method: METHOD_POST,
          body: args,
        };
      },
    }),
    createForeignQualification: builder.mutation({
      query: (args) => {
        return {
          url: URL__CREATE_FOREIGN_QUALIFICATION,
          method: METHOD_POST,
          body: args.data,
          // body: args.data,
        };
      },
    }),
    createForeignSubsidiary: builder.mutation({
      query: (args) => {
        return {
          url: URL__CREATE_FOREIGN_SUBSIDIARY,
          method: METHOD_POST,
          body: args,
        };
      },
    }),
    updateComplianceEntity: builder.mutation({
      query: (args) => {
        return {
          url: URL__UPDATE_COMPLIANCE_ENTITY,
          method: METHOD_POST,
          body: args,
        };
      },
    }),
    setCouponCode: builder.mutation({
      query: (data) => {
        return {
          url: URL__SET_COUPOUN_CODE,
          method: METHOD_POST,
          body: data,
        };
      },
    }),
    getAddOnsList: builder.query({
      query: (args) => {
        return {
          url: URL__GET_ADD_ONS_LIST,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setAddOnCompliances(data));
        } catch (error) {}
      },
    }),
    getCompliancePackages: builder.query({
      query: (args) => {
        return {
          url: URL__GET_COMPLIANCE_PACKAGES,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setSelectedPlan(data));
        } catch (error) {}
      },
    }),
    getUpdatedEntity: builder.query({
      query: (args) => {
        return {
          url: URL__GET_UPDATED_ENTITY_COMPLIANCE,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setComplianceTaxPeriod(data));
        } catch (error) {}
      },
    }),
    getForeignQualifications: builder.query({
      query: (args) => {
        return {
          url: URL__GET_FOREIGN_QUALIFICATIONS,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setForeignQualifications(data));
        } catch (error) {
          dispatch(complianceActions.setForeignQualifications([]));
        }
      },
    }),
    createCompliancePayment: builder.mutation({
      query: (data) => {
        return {
          url: URL__CREATE_COMPLIANCE_PAYMENT,
          method: METHOD_POST,
          body: data,
        };
      },
    }),
    getForeignSubsidiaries: builder.query({
      query: (args) => {
        return {
          url: URL__GET_FOREIGN_SUBSIDIARIES,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setForeignSubsidiaries(data));
        } catch (error) {
          dispatch(complianceActions.setForeignSubsidiaries([]));
        }
      },
    }),
    getForeignShareHolders: builder.query({
      query: (args) => {
        return {
          url: URL__GET_FOREIGN_SHARE_HOLDERS,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setForeignShareHolders(data));
        } catch (error) {
          dispatch(complianceActions.setForeignShareHolders({ shareholders: [] }));
        }
      },
    }),
    getSubscribedComplianceList: builder.query({
      query: (args) => {
        return {
          url: URL__GET_SUBSCRIBED_COMPLIANCE_LIST,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceActions.setSubscribedComplianceList(data));
        } catch (error) {
          dispatch(complianceActions.setSubscribedComplianceList([]));
        }
      },
    }),
    getAllCompliancePeriods: builder.query({
      query: (args) => {
        return {
          url: URL__GET_ALL_COMPLIANCE_PERIODS,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(complianceFiltersActions.setAllCompliancePeriods(data));
        } catch (error) {
          dispatch(complianceFiltersActions.setAllCompliancePeriods([]));
        }
      },
    }),
  }),
});

export const {
  useGetComplianceListQuery,
  useLazyGetComplianceListQuery,
  useCreateForeignShareHolderMutation,
  useCreateForeignSubsidiaryMutation,
  useCreateForeignQualificationMutation,
  useUpdateComplianceEntityMutation,
  useSetCouponCodeMutation,
  useGetAddOnsListQuery,
  useLazyGetAddOnsListQuery,
  useGetCompliancePackagesQuery,
  useGetUpdatedEntityQuery,
  useLazyGetUpdatedEntityQuery,
  useGetForeignQualificationsQuery,
  useLazyGetForeignQualificationsQuery,
  useCreateCompliancePaymentMutation,
  useGetForeignSubsidiariesQuery,
  useLazyGetForeignSubsidiariesQuery,
  useGetForeignShareHoldersQuery,
  useLazyGetForeignShareHoldersQuery,
  useGetSubscribedComplianceListQuery,
  useLazyGetSubscribedComplianceListQuery,
  useGetAllCompliancePeriodsQuery,
} = complianceApiSlice;
