import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CompliancePage.module.css";
import ComplianceFilter from "./ComplianceFilter";
import ComplianceBanner from "./ComplianceBanner";
import AutomatedComplianceList from "./AutomatedComplianceList";
import Wrapper from "../../UI/Wrapper/Wrapper";
import SubscriptionOverlay from "./ComplianceSubscriptions/SubscriptionOverlay";
import ComplianceTasksList from "./ComplianceTasksList";
import LoadingComponent from "../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../UI/Error/ErrorComponent";
import { complianceActions } from "./reducers/compliance-slice";
import ComplianceComingSoonPage from "./ComplianceComingSoonPage";
import { useLazyGetSubscribedComplianceListQuery } from "./apis/complianceApi-slice";
import { useEffect } from "react";
import { useGetEntitiesQuery } from "../Entities/apis/entityApi-slice";
import { complianceFiltersActions } from "./reducers/complianceFilters-slice";

const CompliancePage = ({ loadFromState }) => {
  const dispatch = useDispatch();

  const { complianceSubscriptionStatus } = useSelector(
    (state) => state.compliance
  );

  const { complianceYear, complianceEntity, compliancePeriod } = useSelector(
    (state) => state.complianceFilters
  );

  const [showSubscriptionOverlay, setShowSubscriptionOverlay] = useState(false);

  const { isLoading: isEntitiesLoading } = useGetEntitiesQuery();

  const [
    getSubscribedComplianceList,
    { isFetching: isLoading, isSuccess, isError },
  ] = useLazyGetSubscribedComplianceListQuery();

  useEffect(() => {
    if (loadFromState?.filters) {
      dispatch(
        complianceFiltersActions.setComplianceFiltersFromState(
          loadFromState.filters
        )
      );
    }
  }, []);

  useEffect(() => {
    const fetchSubscribedComplianceList = async () => {
      if (!complianceYear || !compliancePeriod || !complianceEntity) return;
      try {
        await getSubscribedComplianceList({
          tax_year: complianceYear,
          filing_period: compliancePeriod.replaceAll(" ", ""),
          entity_id: complianceEntity?.entityId,
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubscribedComplianceList();
  }, [complianceYear, compliancePeriod, complianceEntity]);

  const handleShowSubscription = () => {
    dispatch(complianceActions.setComplianceEntity(complianceEntity));
    dispatch(complianceActions.setComplianceYear(complianceYear));
    setShowSubscriptionOverlay(true);
  };

  const handleCloseSubscription = () => {
    setShowSubscriptionOverlay(false);
  };

  let content = null;
  if (isLoading || isEntitiesLoading) {
    content = <LoadingComponent />;
  } else if (isError) {
    content = <ErrorComponent message="Failed to load compliance list" />;
  } else if (isSuccess) {
    if (complianceSubscriptionStatus === "pending") {
      content = (
        <>
          <ComplianceBanner
            actionTitle="Get Started"
            onClickAction={handleShowSubscription}
          />
          <AutomatedComplianceList />
        </>
      );
    } else if (complianceSubscriptionStatus === "completed") {
      content = <ComplianceTasksList />;
    }
  }

  return (
    <Wrapper title="Compliance">
      <div className={styles.container}>
        <ComplianceFilter loadFromState={loadFromState?.filters} />
        {content}
        {showSubscriptionOverlay && (
          <SubscriptionOverlay onClose={handleCloseSubscription} />
        )}
      </div>
    </Wrapper>
  );
};

export default CompliancePage;
