import { createSlice } from "@reduxjs/toolkit";
import { convertISODateToStr } from "../../../../utils/common-utils";
import { getStateCode, SFT_STATES_LIST } from "../../../../utils/listUSStates";

const initialState = {
  isSubscribed: false,
  hasRequstedQuotation: false,
  hasCompletedCompliance: false,
  isEntityDataVerified: false,
  isSubsidiaryDataVerified: false,
  selectedYear: 2023,
  selectedEntity: null,
  complianceDate: {},
  complianceList: [],
  complianceSubscriptionStatus: "pending",
  isComplianceDetailsConfirmed: false,
  selectedPlan: {},
  addOnsList: [],
  addOnCompliances: [],
  addOnServices: [],
  taxPeriod: {},
  complianceTasks: {},
  foreignQualifications: [],
  foreignSubsidiaries: [],
  foreignShareHolders: [],
  compliancePackages: [],
  priceList: {
    accounting: { actualPrice: 150, discountedPrice: 150, excluded: true },
    audit: { actualPrice: 400, discountedPrice: 400, excluded: true },
  },
  totalPrice: 0,
  discountedPrice: 0,
  isAccountingPlan: false,
  isAuditPlan: false,
};

const calculatePrice = (priceList, key) => {
  return Object.values(priceList).reduce(
    (sum, item) => sum + (item?.excluded ? 0 : item[key] || 0),
    0
  );
};

const calculateAddOnPrice = (addOnCompliances) => {
  return addOnCompliances.reduce(
    (sum, addOn) => sum + addOn.compliancePrice * addOn.count,
    0
  );
};

const calculateAddOnSubsidiariesFormCount = (
  count5471,
  count8865,
  includedSubsidiaries
) => {
  let adddon5471Count = 0;
  let addon8865Count = 0;

  let package5471Count = includedSubsidiaries;
  let package8865Count = 0;

  const remaining = includedSubsidiaries - count5471;

  if (remaining > 0) {
    package8865Count = Math.min(count8865, remaining);
    package5471Count = includedSubsidiaries - package8865Count;
  }

  if (count5471 > 0) {
    if (remaining < 0) {
      adddon5471Count = Math.abs(remaining);
      addon8865Count = count8865;
    } else {
      addon8865Count = Math.max(0, count8865 - remaining);
    }
  } else {
    addon8865Count = Math.max(0, count8865 - includedSubsidiaries);
  }

  return {
    adddon5471Count,
    addon8865Count,
    package5471Count,
    package8865Count,
  };
};

const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    setSubscriptionStatus(state, action) {
      state.isSubscribed = action.payload;
    },
    setComplianceCompletionStatus(state, action) {
      state.hasCompletedCompliance = action.payload;
    },
    setQuotationRequestStatus(state, action) {
      state.hasRequstedQuotation = action.payload;
    },
    setComplianceListData(state, action) {
      const requiredCompliances = action.payload?.required_compliances || [];
      let compliances = [];
      requiredCompliances.forEach((compliance) => {
        compliances.push({
          compliance: compliance.compliance_name,
          forms: [compliance.compliance_type],
          dueDate: convertISODateToStr(compliance.compliance_due_date),
        });
      });
      state.complianceList = compliances;
    },
    setComplianceTaxPeriod(state, action) {
      const isYearSet =
        state.taxPeriod?.year == action.payload?.current_tax_year;
      let period = { from: null, to: null };
      if (isYearSet && action.payload?.filing_period) {
        const periodStr = action.payload?.filing_period?.split("-");
        period = {
          from: periodStr[0]?.trim(),
          to: periodStr[1]?.trim(),
        };
      }
      const data = {
        ...state.taxPeriod,
        issuedShares: action.payload?.issued_shares,
        authorizedShares: action.payload?.authorized_shares,
        taxClassification: action.payload?.irs_tax_classification,
        period,
        // entityType: action.payload?.current_tax_form,
      };
      state.taxPeriod = data;
    },

    setComplianceDate(state, action) {
      const { fromDate, toDate } = action.payload;
      state.complianceDate = { fromDate, toDate };
    },
    setComplianceYear(state, action) {
      state.taxPeriod = { ...state.taxPeriod, year: action.payload };
    },
    setComplianceEntity(state, action) {
      const entity = action.payload;
      state.taxPeriod = {
        ...state.taxPeriod,
        entityType: entity?.entityType,
        incorporationDate: entity?.incorporationDate,
      };
      state.selectedEntity = entity;
    },
    setSelectedEntity(state, action) {
      state.selectedEntity = action.payload;
    },
    setForeignQualifications(state, action) {
      let foreignQualifications = [];
      action.payload.forEach((qualification) => {
        foreignQualifications.push({
          state: qualification.foreign_state,
          stateId: qualification.foreign_state_id,
          registrationCertificate:
            qualification.registration_certificate_document,
          registrationDate: qualification.foreign_state_registration_date,
        });
      });
      state.foreignQualifications = foreignQualifications;

      if (foreignQualifications.length > 0) {
        const stateTaxAddOn = state.addOnCompliances.find(
          (item) => item.title === "ST"
        );

        let addOnStates = [];
        let countSFT = 0;
        foreignQualifications.forEach((qualification) => {
          const stateCode = getStateCode(qualification.state);
          const isStateAlreadyAdded = state.addOnCompliances.find(
            (item) => item.title === stateCode
          );

          if (!isStateAlreadyAdded) {
            addOnStates.push({
              title: stateCode,
              complianceName: stateTaxAddOn.complianceName,
              complianceType: stateTaxAddOn.complianceType,
              compliancePrice: stateTaxAddOn.compliancePrice,
              complianceForm: stateTaxAddOn.complianceForm,
              id: stateTaxAddOn.id,
              count: 1,
            });
          }
        });

        const sftAddOnIndex = state.addOnCompliances?.findIndex((item) =>
          item.title?.startsWith("SFT")
        );

        if (sftAddOnIndex > -1) {
          state.addOnCompliances[sftAddOnIndex].count =
            foreignQualifications.length;
        }

        state.addOnCompliances = [...state.addOnCompliances, ...addOnStates];

        let addOnPrice = calculateAddOnPrice(state.addOnCompliances);

        state.priceList = {
          ...state.priceList,
          addOns: {
            actualPrice: addOnPrice,
            discountedPrice: addOnPrice,
          },
        };

        state.totalPrice = calculatePrice(state.priceList, "actualPrice");
        state.discountedPrice = calculatePrice(
          state.priceList,
          "discountedPrice"
        );
      }
    },
    setSelectedPlan(state, action) {
      let plans = [];
      action.payload?.packages?.forEach((plan) => {
        let complianceFeatures = [];
        plan?.package_compliances?.forEach((compliance) => {
          complianceFeatures.push({
            title: compliance.compliance_form,
            count: compliance.quantity,
            id: compliance.compliance_id,
            type: compliance.type,
          });
          if (compliance.compliance_form === "5471") {
            complianceFeatures.push({
              title: "8865",
              count: 0,
              id: compliance.compliance_id,
              type: compliance.type,
            });
          }
        });
        plans.push({
          planName: "Compliance360 plan",
          planId: plan?.package_id,
          planTitle: plan?.package_name?.startsWith("US + FOREIGN SUBSIDIARY")
            ? "US + Subsidiary"
            : "US",
          packageName: plan?.package_name,
          planPrice: plan?.package_actual_price,
          planDiscountedPrice: plan?.package_discounted_price,
          planFeautes: complianceFeatures,
        });
      });
      state.compliancePackages = plans;
      const plan = plans.find((p) => p.planTitle === "US");

      state.selectedPlan = plan;
      state.priceList = {
        ...state.priceList,
        compliance: {
          actualPrice: plan?.planPrice,
          discountedPrice: plan?.planDiscountedPrice,
        },
      };
      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },
    setAddOnCompliances(state, action) {
      let addOns = [];
      let addOnPrice = 0;
      action.payload?.addons?.forEach((addOn) => {
        addOns.push({
          title: addOn.compliance_forms,
          complianceName: addOn.complaince_name,
          complianceType: addOn.complaince_type,
          compliancePrice: addOn?.compliance_price || 0,
          complianceForm: addOn.compliance_forms,
          id: addOn.$id,
          count:
            addOn.compliance_forms === "ST" ||
            addOn.compliance_forms?.startsWith("SFT") ||
            addOn.compliance_forms === "5471" ||
            !addOn.compliance_price
              ? 0
              : 1,
        });
        if (addOn.compliance_forms === "5471") {
          addOns.push({
            title: "8865",
            complianceName: addOn.complaince_name,
            complianceType: addOn.complaince_type,
            complianceForm: addOn.compliance_forms,
            compliancePrice: addOn.compliance_price || 0,
            id: addOn.$id,
            count: 0,
          });
        }
        addOnPrice += addOn.compliance_price * 1;
      });
      state.addOnCompliances = addOns;
      state.addOnsList = addOns;
      state.priceList = {
        ...state.priceList,
        addOns: {
          actualPrice: addOnPrice,
          discountedPrice: addOnPrice,
        },
      };

      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );

      action.payload?.services?.forEach((service) => {
        state.addOnServices.push({
          serviceName: service.service_name,
          serviceId: service.service_id,
        });
      });
    },

    calculateTotalPrice(state) {
      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },

    updateAddOnCompliances(state, action) {
      const { addOn, count } = action.payload;
      state.addOnCompliances = state.addOnCompliances.map((item) =>
        item.title === addOn ? { ...item, count: count } : item
      );

      let addOnPrice = calculateAddOnPrice(state.addOnCompliances);

      state.priceList = {
        ...state.priceList,
        addOns: {
          actualPrice: addOnPrice,
          discountedPrice: addOnPrice,
        },
      };

      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },
    setForeignSubsidiaries(state, action) {
      // state.foreignSubsidiaries = action.payload;
      let foreignSubsidiaries = [];
      let count5471 = 0;
      let count8865 = 0;
      action.payload?.forEach((subsidiary) => {
        let formName = "";
        if (subsidiary.perc_owned_by_parent >= 10) {
          if (subsidiary.entity_type == "Partnership") {
            formName = "8865";
            count8865++;
          } else {
            formName = "5471";
            count5471++;
          }
        }
        foreignSubsidiaries.push({
          entityName: subsidiary.entity_name,
          entitityId: subsidiary.$id,
          entityType: subsidiary.entity_type,
          incorporationCountry: subsidiary.incorporation_country,
          incorporationState: subsidiary.incorporation_state,
          parentPercentage: subsidiary.perc_owned_by_parent,
          parentEntityId: subsidiary.parent_entity_id,
          is5471Applicable: subsidiary.perc_owned_by_parent >= 10,
          id: subsidiary.$id,
          formName,
        });
      });
      state.foreignSubsidiaries = foreignSubsidiaries;

      if (count5471 + count8865 > 0) {
        const plan = state.compliancePackages.find(
          (p) => p.planTitle === "US + Subsidiary"
        );

        state.selectedPlan = plan;
        state.priceList = {
          ...state.priceList,
          compliance: {
            actualPrice: plan?.planPrice,
            discountedPrice: plan?.planDiscountedPrice,
          },
        };

        const INCLUDED_SUBSIDIARIES = state.selectedPlan?.planFeautes?.find(
          (feature) => feature.title === "5471"
        )?.count; // Number of subsidiaries included in base package

        const {
          adddon5471Count,
          addon8865Count,
          package5471Count,
          package8865Count,
        } = calculateAddOnSubsidiariesFormCount(
          count5471,
          count8865,
          INCLUDED_SUBSIDIARIES
        );

        const planFeautes = state.selectedPlan?.planFeautes;

        const newPlanFeautes = planFeautes.map((feature) => {
          if (feature.title === "5471") {
            return { ...feature, count: package5471Count };
          }
          if (feature.title === "8865") {
            return { ...feature, count: package8865Count };
          }
          return feature;
        });

        state.selectedPlan = {
          ...state.selectedPlan,
          planFeautes: newPlanFeautes,
        };

        state.addOnCompliances = state.addOnCompliances.map((item) => {
          if (item.title !== "5471" && item.title !== "8865") return item;

          return {
            ...item,
            count: item.title === "5471" ? adddon5471Count : addon8865Count,
          };
        });

        let addOnPrice = calculateAddOnPrice(state.addOnCompliances);

        state.priceList = {
          ...state.priceList,
          addOns: {
            actualPrice: addOnPrice,
            discountedPrice: addOnPrice,
          },
        };
      } else {
        const plan = state.compliancePackages.find((p) => p.planTitle === "US");

        state.selectedPlan = plan;
        state.priceList = {
          ...state.priceList,
          compliance: {
            actualPrice: plan?.planPrice,
            discountedPrice: plan?.planDiscountedPrice,
          },
        };
      }
      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },
    setForeignShareHolders(state, action) {
      let foreignShareHolders = [];
      const shareholders = action.payload?.shareholders || [];
      let count5472 = 0;
      shareholders?.forEach((shareHolder) => {
        const country =
          shareHolder.shareholder_country ||
          shareHolder.shareholder_tax_residency;

        if (country !== "United States") {
          const percentage = shareHolder.share_perc;
          foreignShareHolders.push({
            name: shareHolder.shareholder_name,
            country:
              shareHolder.shareholder_tax_residency ||
              shareHolder.shareholder_country,
            percentage: percentage,
            noOfShares: shareHolder.shareholder_no_of_shares,
            id: shareHolder.$id,
            is5472Applicable: percentage >= 25,
            formName: percentage >= 25 ? "5472" : "",
          });
          if (percentage >= 25) {
            count5472++;
          }
        }
      });
      foreignShareHolders.sort((a, b) => {
        // Sort by is5472Applicable (true comes first)
        return b.is5472Applicable - a.is5472Applicable;
      });
      state.foreignShareHolders = foreignShareHolders;

      const INCLUDED_SHARE_HOLDERS = state.selectedPlan?.planFeautes?.find(
        (feature) => feature.title === "5472"
      )?.count; // Number of subsidiaries included in base package

      state.addOnCompliances = state.addOnCompliances.map((item) => {
        if (item.title !== "5472") return item;

        const additionalShareHolders = Math.max(
          0,
          count5472 - INCLUDED_SHARE_HOLDERS
        );
        return {
          ...item,
          count: additionalShareHolders,
        };
      });

      let addOnPrice = calculateAddOnPrice(state.addOnCompliances);

      state.priceList = {
        ...state.priceList,
        addOns: {
          actualPrice: addOnPrice,
          discountedPrice: addOnPrice,
        },
      };

      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },
    setIsAccountingPlan(state, action) {
      state.isAccountingPlan = action.payload;
      const excluded = action.payload ? false : true;
      const accounting = { ...state.priceList.accounting, excluded };

      const audit = { ...state.priceList.audit };
      const newAudit = {
        ...audit,
        actualPrice: audit.actualPrice,
        discountedPrice: action.payload ? 300 : audit.actualPrice,
      };
      state.priceList = {
        ...state.priceList,
        accounting,
        audit: newAudit,
      };

      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },

    setIsAuditPlan(state, action) {
      state.isAuditPlan = action.payload;
      const excluded = action.payload ? false : true;
      const audit = { ...state.priceList.audit };
      const newAudit = {
        ...audit,
        actualPrice: audit.actualPrice,
        discountedPrice: state.isAccountingPlan ? 300 : audit.actualPrice,
        excluded,
      };
      state.priceList = {
        ...state.priceList,
        audit: newAudit,
      };
      state.totalPrice = calculatePrice(state.priceList, "actualPrice");
      state.discountedPrice = calculatePrice(
        state.priceList,
        "discountedPrice"
      );
    },
    setDiscountedPrice(state, action) {
      state.discountedPrice = action.payload;
    },
    setIsComplianceDetailsConfirmed(state, action) {
      state.isComplianceDetailsConfirmed = action.payload;
    },
    resetAddOnCompliances(state) {
      state.addOnCompliances = [...state.addOnsList];
      state.foreignQualifications = [];
      state.foreignSubsidiaries = [];
      state.foreignShareHolders = [];
      state.isComplianceDetailsConfirmed = false;
      state.priceList = {
        accounting: { actualPrice: 150, discountedPrice: 150, excluded: true },
        audit: { actualPrice: 400, discountedPrice: 400, excluded: true },
      };
      state.isAccountingPlan = false;
      state.isAuditPlan = false;
      state.complianceDate = {};
    },
    setSubscribedComplianceList(state, action) {
      let complianceTasks = {};

      action.payload?.forEach((task) => {
        if (!complianceTasks[task.compliance_type]) {
          complianceTasks[task.compliance_type] = [];
        }
        complianceTasks[task.compliance_type].push({
          name: task.compliance_name,
          form: task.compliance_forms,
          id: task.compliance_id,
          ticketNumber: "",
          taskStatus: "In Progress",
          entityName: state.selectedEntity?.entityName,
          incorporationCountry: state.selectedEntity?.incorporationCountry,
          paymentStatus: "paid",
          type: task.compliance_type,
          quantity: task.compliance_quantity,
        });
      });
      state.complianceTasks = complianceTasks;
      if (action.payload?.length > 0) {
        state.complianceSubscriptionStatus = "completed";
      } else {
        state.complianceSubscriptionStatus = "pending";
      }
    },
  },
});

export const complianceActions = complianceSlice.actions;

export default complianceSlice.reducer;
