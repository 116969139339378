import { useState, useMemo } from "react";
import styles from "./AddForeignState.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import {
  getFilteredUSStatesList,
  US_STATES_LIST,
} from "../../../../../../utils/listUSStates";
import {
  LBL__STATE,
  LBL__REGISTRATION_CERTIFICATE,
  LBL__REGISTRATION_DATE,
} from "../../../../../../consts/TextConstants";
import { ERROR_MSG__SELECT_STATE } from "../../../../../../consts/ErrorMessages";
import FileInputDragDrop from "../../../../../UI/Input/FileInputDragDrop";
import Button from "../../../../../UI/Button/Button";
import { formatRequestData } from "../../../../../../utils/dataFormatter";
import { PAYLD__CREATE_FOREIGN_STATE } from "../../../../../../consts/PayloadConstants";
import {
  useCreateForeignQualificationMutation,
  useLazyGetForeignQualificationsQuery,
} from "../../../apis/complianceApi-slice";
import { convertToISODate } from "../../../../../../utils/common-utils";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";

const AddForeignState = ({
  entityId,
  onClose,
  foreignStates,
  incorporationState,
  incorporationDate,
  complianceDate,
}) => {
  const [foreignState, setForeignState] = useState("");
  const [showForeignStateError, setShowForeignStateError] = useState(false);
  const [registrationCertificate, setRegistrationCertificate] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(new Date(complianceDate?.fromDate));
  const [error, setError] = useState({ isError: false });

  const existingForeignStates = useMemo(() => {
    let selectedForeignStates = [incorporationState];

    foreignStates.forEach((item) => {
      selectedForeignStates.push(item.state);
    });

    return US_STATES_LIST.filter(function (item) {
      return this.indexOf(item.name) < 0;
    }, selectedForeignStates);
  }, []);

  const [addForeignState, { isLoading }] =
    useCreateForeignQualificationMutation();

  const [
    getForeignQualifications,
    { isLoading: isForeignQualificationsLoading },
  ] = useLazyGetForeignQualificationsQuery();

  const handleForeignStateChange = (selectedState) => {
    setForeignState(selectedState.key);
    setShowForeignStateError(false);
  };

  const handleRegistrationCertificateChange = (file) => {
    setRegistrationCertificate(file);
  };

  const handleAddForeignState = async () => {
    if (!foreignState) {
      setShowForeignStateError(true);
      return;
    }
    try {
      const data = formatRequestData(
        {
          entityId: entityId,
          registrationDate: convertToISODate(registrationDate),
          state: foreignState,
        },
        ["registeration_certificate_document", registrationCertificate],
        PAYLD__CREATE_FOREIGN_STATE
      );
      const params = {
        entity_id: entityId,
        registration_date: convertToISODate(registrationDate),
        state: foreignState,
      };

      // const params = {};
      const response = await addForeignState({ data }).unwrap();
      await getForeignQualifications({ entity_id: entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <div className={styles.container}>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
      <div className={styles.form_inputs}>
        <EditInputItem
          name="registrationCertificate"
          type="custom"
          labelTitle={LBL__REGISTRATION_CERTIFICATE}
          inputcontainerstyle={styles.file_input_container}
        >
          <FileInputDragDrop
            onChange={handleRegistrationCertificateChange}
            className={styles.file_input_drag_drop}
          />
        </EditInputItem>

        <EditInputItem
          name="incorporationState"
          type="autocomplete"
          labelTitle={`*${LBL__STATE}`}
          value={foreignState || ""}
          defaultValue={foreignState}
          customFilter={true}
          filteredListFunc={(value) =>
            getFilteredUSStatesList(value, existingForeignStates)
          }
          onChange={handleForeignStateChange}
          // autocompleteSize={styles.autocomplete}
          showError={showForeignStateError}
          errorMessage={ERROR_MSG__SELECT_STATE}
        />
        <EditInputItem
          name="registrationDate"
          type="datepicker"
          labelTitle={LBL__REGISTRATION_DATE}
          onChange={(value) => setRegistrationDate(value)}
          value={registrationDate}
          startDate={new Date(incorporationDate)}
          endDate={new Date(Math.min(new Date(), complianceDate?.toDate))}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={handleAddForeignState}
        buttonstyle="default-blue"
        isLoading={isLoading}
      >
        Submit
      </Button>
    </div>
  );
};

export default AddForeignState;
