import { useState } from "react";
import styles from "./Collapsible.module.css";
import arrowIcon from "/assets/common/Arrow_White.svg";
import Button from "../Button/Button";

const Collapsible = ({
  children,
  title,
  className,
  headerstyle,
  contentstyle,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`${styles.header} ${headerstyle}`}>
        <div className={styles.title}>{title}</div>
        <Button
          onClick={handleToggle}
          className={`${styles.btn__toggle} ${
            isExpanded ? styles.expanded : ""
          }`}
        >
          <img src={arrowIcon} alt="arrow" />
        </Button>
      </div>
      <div
        className={`${styles.content} ${contentstyle} ${
          isExpanded ? styles.expanded : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
