export const PUBLIC_URL = import.meta.url;
export const flowTypes = {
  SIGN_IN: "sign_in_flow",
  FORGOT_PASSWORD: "forgot_password_flow",
  REGISTER: "register_flow",
};

export const entityStructures = {
  PARENT: "Parent",
  SUBSIDIARY: "Subsidiary",
  STANDALONE: "Standalone",
};

export const documentTypes = {
  INCORPORATION_CERTIFICATE: 'incorporation_certificate_document',
  EIN_LETTER: 'ein_letter_document',
  CAP_TABLE: 'partnership_agreement_document',
  BY_LAWS: 'by_laws_document',
};

export const WEEK_DAYS = ["S", "M", "T", "W", "T", "F", "S"];
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const MONTH_NAMES_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const tabStyles = {
  ROUND_OUT_BORDER: "RoundOutBorder",
  UNDERLINED: "underline",
  BACKGROUND_BOX: "box",
};

export const entityTabs = { PARENT: "Parent", SUBSIDIARY: "Subsidiary" };

export const entityDetailsTabs = { ENTITY: "ENTITY", BOARD: "BOARD", CAP_TABLE: "CAP TABLE" };

export const entityEditForms = {
  REGISTRATION: "REGISTRATION",
  IRS: "IRS",
  AGENT: "AGENT",
  FOREIGN: "FOREIGN",
  SECONDARY: "SECONDARY",
  OTHER_ENTITY: "OTHER_ENTITY",
  BOARD_DIRECTORS: "DIRECTORS",
  BOARD_OFFICERS: "OFFICERS",
  OWNERSHIP: "OWNERSHIP",
  ADD_ENTITY: "ADD_ENTITY",
};

export const capTableStakes = {
  SHARES: { key: "Shares", value: "Shares" },
  SAFES: { key: "Safes", value: "SAFEs" },
  STOCK_OPTION_POOLS: { key: "Options", value: "Stock Option Pools" },
};

export const entityNexusTypes = {
  PHYSICAL_OFFICE: "Physical Office",
  REVENUE_FROM_STATE: "Revenue from State",
  PAYROLL: "Payroll",
};


export const complianceConfirmationTabs = {
  TAX_PERIOD: "Tax Period",
  FOREIGN_QUALIFICATIONS: "Foreign Qualifications",
  FOREIGN_SUBSIDIARIES: "Foreign Subsidiaries",
  FOREIGN_SHAREHOLDERS: "Foreign Shareholders",
  CONFIRMED_DATA: "Confirmed Data",
};

export const complianceEditForms = {
  FOREIGN_QUALIFICATIONS: "ForeignQualifications",
  FOREIGN_SUBSIDIARIES: "ForeignSubsidiaries",
  FOREIGN_SHAREHOLDERS: "ForeignShareholders",
  TAX_PERIOD: "TaxPeriod",
  IRS_CLASSIFICATION: "IRSClassification",
  DISSOLVE_STATE: "DissolveState",
  DISSOLVE_ENTITY: "DissolveEntity",
  UNSUBSIDIZE_ENTITY: "UnsubsidizeEntity",
  REMOVE_SHAREHOLDER: "RemoveShareholder",
  ISSUED_SHARES: "IssuedShares",
  AUTHORIZED_SHARES: "AuthorizedShares",
};
