import { useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "../../UI/Button/Button";
import styles from "./ComplianceBanner.module.css";

const ComplianceBanner = ({
  // year,
  // period,
  // entity,
  // slogan,
  actionTitle,
  onClickAction,
}) => {
  const {
    complianceYear,
    bannerStatus,
    complianceFormattedPeriod,
    complianceEntityName,
    complianceEntity,
  } = useSelector((state) => state.complianceFilters);

  const slogan = useMemo(
    () =>
      bannerStatus === "start early"
        ? {
            subtitle: "Tax season is around the corner",
            title: "Start Early to be Compliant",
          }
      : {
          subtitle: "",
          title: "Compliance is due!",
        },
    [bannerStatus]
  );

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <div className={styles.details}>
          <div className={styles.year}>{complianceYear}</div>
          <div className={styles.period}>{complianceFormattedPeriod}</div>
          <div className={styles.entity}>{complianceEntityName}</div>
        </div>
        <div className={styles.slogan}>
          <div className={styles.slogan_subtitle}>{slogan.subtitle}</div>
          <div className={styles.slogan_title}>{slogan.title}</div>
        </div>
        {complianceEntity?.entityType === "C-Corp" && (
          <Button
            buttonstyle="default-blue"
            className={styles.btn__action}
            onClick={onClickAction}
          >
            {actionTitle}
          </Button>
        )}
        {complianceEntity?.entityType !== "C-Corp" && (
          <div className={styles.coming_soon_text}>Coming Soon...</div>
        )}
      </div>
    </div>
  );
};

export default ComplianceBanner;
