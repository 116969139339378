import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StripeSuccessPage.module.css";
import {
  getStripeRedirectUrls,
  removeStripeRedirectUrls,
} from "../../utils/authentication";
import logo from "/assets/Logo.svg";
import timer from "/assets/common/Timer.svg";
import successIcon from "/assets/common/Success_Tick.svg";

const StripeSuccessPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  const { successUrl, state } = getStripeRedirectUrls();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      removeStripeRedirectUrls();
      navigate(successUrl, { state: { filters: state } });
    }
  }, [countdown]);

  return (
    <div className={styles.container}>
      <div className={styles.logo_container}>
        <img src={logo} alt="" />
        <p>Merze</p>
      </div>
      <div className={styles.content_container}>
        <img src={successIcon} alt="" />
        <p className={styles.payment_complete}>Payment Complete</p>
        <p className={styles.please_wait}>PLEASE WAIT WHILE WE REDIRECT YOU TO THE DASHBOARD</p>
      </div>
      <div className={styles.timer}>
        <img src={timer} alt="" />
        <p><span>{countdown}</span> Seconds...</p>
      </div>
    </div>
  );
};

export default StripeSuccessPage;
