import styles from "./SignInUpPage.module.css";
import {
  BTN__GOOGLE_SIGNIN,
  LBL__PRIVACY_POLICY,
  LBL__TERMS_AND_CONDITIONS,
  BTN__RETRY,
} from "../../consts/TextConstants.jsx";
import LoginForm from "./LoginForm.jsx";
import LogoBox from "../UI/LogoBox.jsx";
import Button from "../UI/Button/Button.jsx";
import googleIcon from "/assets/login/Google.svg";
import { useState, useEffect } from "react";
import ReadPolicyAndTerms from "./ReadPolicyAndTerms.jsx";
import { AnimatePresence } from "framer-motion";
import { PRIVACY_POLICY_CONTENT } from "../../consts/PrivacyPolicyText.jsx";
import { TERMS_AND_CONDITIONS } from "../../consts/TermsAndConditions.jsx";
import MessageDialog from "../UI/Modal/MessageDialog.jsx";
import { loginWithGoogle, getUser } from "../../lib/auth";

const SignInUpPage = () => {
  const [disclaimer, setDisclaimer] = useState({ show: false, type: null });
  const [error, setError] = useState({ isError: false });

  const handleDisclaimerClick = (type) => {
    setDisclaimer({ show: true, type });
  };

  const handleDisclaimerClose = () => {
    setDisclaimer({ show: false, type: null });
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
    } catch (err) {
      setError({
        isError: true,
        title: "Error",
        message: err.message,
      });
    }
  };

  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   const checkUser = async () => {
  //     try {
  //       // const userData = await getUser();
  //       // setUser(userData);
  //     } catch (error) {
  //       setUser(null);
  //     }
  //   };

  //   checkUser();
  // }, []);

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <div className={styles.form_container}>
      <LoginForm />
      <Button
        className={styles.btn__google_signin}
        buttonstyle="white-box-shadow"
        onClick={handleGoogleLogin}
      >
        <img src={googleIcon} alt="" />
        {BTN__GOOGLE_SIGNIN}
      </Button>
      <div className={styles.disclaimer_text}>
        By continuing, you agree with our
        <span onClick={() => handleDisclaimerClick(LBL__PRIVACY_POLICY)}>
          &nbsp;Privacy Policy&nbsp;
        </span>
        and
        <span onClick={() => handleDisclaimerClick(LBL__TERMS_AND_CONDITIONS)}>
          &nbsp;T & C.
        </span>
      </div>

      <AnimatePresence>
        {disclaimer.show && (
          <ReadPolicyAndTerms
            title={disclaimer.type}
            content={
              disclaimer.type == LBL__PRIVACY_POLICY ? (
                <PRIVACY_POLICY_CONTENT />
              ) : (
                <TERMS_AND_CONDITIONS />
              )
            }
            onClose={handleDisclaimerClose}
          />
        )}
      </AnimatePresence>
      {error.isError && (
        <MessageDialog
          title={error?.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
    </div>
  );
};

export default SignInUpPage;
