import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "../UI/LoadingIndicator/LoadingIndicator.jsx";
// import { useGoogleCallBackQuery } from "./apis/googleLoginApi-slice";
import styles from "./GoogleLoginPage.module.css";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useLazyGetFlagsQuery } from "../Dashboard/apis/dashboardApi-slice.jsx";
import { loginActions } from "../../store/login-slice.jsx";
import MessageDialog from "../UI/Modal/MessageDialog.jsx";
import { BTN__RETRY, BTN__CLOSE } from "../../consts/TextConstants.jsx";
import LoadingComponent from "../UI/LoadingIndicator/LoadingComponent.jsx";
import { account, functions } from "../../lib/appwrite";
import { logoutUser } from "../../lib/auth";
let isFirstTime = true;

const GoogleLoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const dispatch = useDispatch();
  const [trigger] = useLazyGetFlagsQuery();
  const [message, setMessage] = useState({ show: false, isError: false });

  const handleGoogleCallback = async () => {
    try {
      const session = await account.get();
      const response = await functions.createExecution(
        import.meta.env.VITE_APPWRITE_FUNCTION_ID
      );
      const flagResult = await trigger().unwrap();
      if (flagResult?.flags?.isBasicInfoComplete) {
        navigate("/home");
      } else {
        dispatch(loginActions.setShowUserDetailsForm(true));
        navigate("/", { state: { showUserDetailsPage: true } });
      }
    } catch (error) {
      setMessage({
        show: true,
        isError: true,
        title: "Error",
        message: error?.message || "Something went wrong",
      });
      await logoutUser();
    }
  };
  useEffect(() => {
    if (isFirstTime) {
      isFirstTime = false;
      handleGoogleCallback();
    }
  }, []);

  const handleCloseMessage = () => {
    const isError = message.isError;
    setMessage({ show: false });
    if (isError) {
      navigate("/");
    }
  };

  return (
    <div className={styles.main}>
      <LoadingComponent />
      {message.show && (
        <MessageDialog
          isError={message?.isError}
          title={message?.title}
          message={message?.message}
          buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
          onClose={handleCloseMessage}
        />
      )}
    </div>
  );
};

export default GoogleLoginPage;
