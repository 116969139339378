import { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ForeignQualifications.module.css";
import AddNewDetailButton from "../../ui-compliance/AddNewDetailButton";
import locationIcon from "/assets/common/Location.svg";
import MoreInfoButton from "../../ui-compliance/MoreInfoButton";
import InfoBox from "../../ui-compliance/InfoBox";
import { complianceEditForms } from "../../../../../consts/Constants";
import EditOverlay from "./EditForms/EditOverlay";
import { useGetForeignQualificationsQuery } from "../../apis/complianceApi-slice";

const ForeignState = ({ foreignState, onDissolve, homeState }) => {
  const isHomeState = foreignState === homeState;
  return (
    <div className={styles.foreign__state}>
      <div className={styles.foreign__state__info}>
        {/* <img src={locationIcon} alt="" /> */}
        <div className={styles.foreign__state__name}>{foreignState}</div>
        {/* <MoreInfoButton name={foreignState} onSelect={onDissolve} /> */}
      </div>

      <InfoBox infostyle={isHomeState ? "light-blue" : "light-violet"}>
        {isHomeState ? "Home State" : "Foreign State"}
      </InfoBox>
    </div>
  );
};

const ForeignQualifications = () => {
  const [editOverlay, setEditOverlay] = useState({ show: false, formName: "" });

  const {  selectedEntity, foreignQualifications, complianceDate } = useSelector(
    (state) => state.compliance
  );
  // const { isLoading } = useGetForeignQualificationsQuery({
  //   entity_id: selectedEntity?.entityId,
  // });

  const handleDissolve = (state) => {
    setEditOverlay({
      show: true,
      formName: complianceEditForms.DISSOLVE_STATE,
      foreignState: state,
    });
  };

  const handleAddForeignState = () => {
    setEditOverlay({
      show: true,
      formName: complianceEditForms.FOREIGN_QUALIFICATIONS,
    });
  };

  const handleCloseEditOverlay = () => {
    setEditOverlay({ show: false, formName: "" });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Confirm other registered states of the entity in the US
      </div>

      <div className={styles.content}>
        <AddNewDetailButton
          label="Add Foreign State"
          className={styles.btn__add}
          onClick={handleAddForeignState}
        />
        {foreignQualifications.map((state) => (
          <ForeignState
            key={state.state}
            foreignState={state.state}
            onDissolve={() => handleDissolve(state)}
            homeState={selectedEntity.incorporationState}
          />
        ))}
      </div>
      {editOverlay.show && (
        <EditOverlay
          onClose={handleCloseEditOverlay}
          formName={editOverlay.formName}
          className={
            editOverlay.formName === complianceEditForms.FOREIGN_QUALIFICATIONS
              ? styles.add_overlay
              : styles.remove_overlay
          }
          entityId={selectedEntity?.entityId}
          foreignStates={foreignQualifications}
          incorporationState={selectedEntity?.state}
          incorporationDate={selectedEntity?.incorporationDate}
          complianceDate={complianceDate}
        />
      )}
    </div>
  );
};

export default ForeignQualifications;
