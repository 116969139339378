import styles from "./InfoBox.module.css";

const InfoBox = ({ children, className, infostyle = "default", ...props }) => {
  let infoBoxStyle = "";
  switch (infostyle) {
    case "light-blue":
      infoBoxStyle = styles.light_blue;
      break;
    case "light-violet":
      infoBoxStyle = styles.light_violet;
      break;
    default:
      break;
  }

  return (
    <div
      className={`${styles.container} ${className} ${infoBoxStyle}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default InfoBox;
