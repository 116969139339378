import { useState } from "react";
import styles from "./EditTaxPeriod.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import { ERROR_MSG__CURRENT_TAX_FORM } from "../../../../../../consts/ErrorMessages";
import Button from "../../../../../UI/Button/Button";
import { MONTH_NAMES_SHORT } from "../../../../../../consts/Constants";
import {
  useUpdateComplianceEntityMutation,
  useLazyGetUpdatedEntityQuery,
  useLazyGetForeignSubsidiariesQuery,
  useLazyGetForeignShareHoldersQuery,
  useLazyGetForeignQualificationsQuery,
} from "../../../apis/complianceApi-slice";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";

const EditTaxPeriod = ({
  fromYear,
  entityId,
  onClose,
  defaultFromDate,
  defaultToDate,
  incorporationDate,
}) => {
  const [showCurrentTaxFormError, setShowCurrentTaxFormError] = useState(false);
  const [error, setError] = useState({ isError: false });

  const [dateError, setDateError] = useState("");
  const [fromDate, setFromDate] = useState(new Date(defaultFromDate));
  const [toDate, setToDate] = useState(new Date(defaultToDate));

  const [updateComplianceEntity, { isLoading }] =
    useUpdateComplianceEntityMutation();

  const [getComplianceEntity, { isLoading: isComplianceEntityLoading }] =
    useLazyGetUpdatedEntityQuery();
  const [getForeignSubsidiaries, { isLoading: isForeignSubsidiariesLoading }] =
    useLazyGetForeignSubsidiariesQuery();
  const [getForeignShareHolders, { isLoading: isForeignShareHoldersLoading }] =
    useLazyGetForeignShareHoldersQuery();
  const [
    getForeignQualifications,
    { isLoading: isForeignQualificationsLoading },
  ] = useLazyGetForeignQualificationsQuery();

  const incorporationYear = incorporationDate?.getFullYear();

  const handleSubmit = async () => {
    try {
      const finalFromDate = new Date(fromDate);
      const finalToDate = new Date(toDate);
      const payload = {
        entity_id: entityId,
        tax_year: fromYear,
        filing_period: `${String(finalFromDate.getDate()).padStart(2, "0")}${
          MONTH_NAMES_SHORT[finalFromDate.getMonth()]
        }-${String(finalToDate.getDate()).padStart(2, "0")}${
          MONTH_NAMES_SHORT[finalToDate.getMonth()]
        }`,
      };
      const response = await updateComplianceEntity(payload).unwrap();
      await getComplianceEntity({ entity_id: entityId });
      await getForeignSubsidiaries({ entity_id: entityId });
      await getForeignShareHolders({ entity_id: entityId });
      await getForeignQualifications({ entity_id: entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  const handleFromDateChange = (value) => {
    setFromDate(value);
    const newFromDate = new Date(value);
    const endDate = new Date(
      newFromDate.getFullYear(),
      newFromDate.getMonth() + 12,
      0
    );
    setToDate(new Date(value.getTime() + 2 * 24 * 60 * 60 * 1000));
  };
  const handleToDateChange = (value) => {
    setToDate(value);
    setFromDate(new Date(value.getTime() - 2 * 24 * 60 * 60 * 1000));
  };
  return (
    <div className={styles.container}>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
      <div className={styles.form_inputs}>
        <EditInputItem
          name="from-period"
          type="datepicker"
          labelTitle={"From"}
          showError={true}
          errorMessage={dateError}
          onChange={(value) => setFromDate(value)}
          value={fromDate}
          startDate={
            incorporationYear == fromYear
              ? new Date(
                  Math.max(
                    new Date(toDate.getFullYear(), toDate.getMonth() - 11, 1),
                    incorporationDate
                  )
                )
              : new Date(
                  Math.max(
                    new Date(toDate.getFullYear(), toDate.getMonth() - 11, 1),
                    new Date(`Jan 01, ${fromYear}`)
                  )
                )
          }
          endDate={
            new Date(
              Math.min(
                toDate.getTime() - 2 * 24 * 60 * 60 * 1000,
                new Date(`Dec 31, ${fromYear}`).getTime()
              )
            )
          }
        />

        <EditInputItem
          name="to-period"
          type="datepicker"
          labelTitle={"To"}
          onChange={(value) => setToDate(value)}
          value={toDate}
          startDate={new Date(fromDate.getTime() + 2 * 24 * 60 * 60 * 1000)}
          endDate={
            new Date(fromDate.getFullYear(), fromDate.getMonth() + 12, 0)
          }
          showError={showCurrentTaxFormError}
          errorMessage={ERROR_MSG__CURRENT_TAX_FORM}
        />
      </div>

      <Button
        className={styles.btn__submit}
        onClick={handleSubmit}
        buttonstyle="default-blue"
        isLoading={
          isLoading ||
          isForeignShareHoldersLoading ||
          isForeignSubsidiariesLoading ||
          isForeignQualificationsLoading
        }
      >
        Submit
      </Button>
    </div>
  );
};

export default EditTaxPeriod;
