import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./EntityEditPage.module.css";
import EditOverlay from "../../Edit/EditOverlay";
import EditPrimaryEntityDetails from "./EditPrimaryEntityDetails";
import { entityEditForms } from "../../../../consts/Constants";
import AddForeignState from "./AddForeignState";
import AddBoardDetails from "./AddBoardDetails";
import {
  LBL__ADD_DIRECTOR,
  LBL__ADD_FOREIGN_STATE,
  LBL__ADD_OFFICER,
  LBL__AGENT_INFO,
  LBL__ENTITY_INFO,
  LBL__IRS_INFO,
  TITLE__ENTITY_INFORMATION,
  TITLE__ADD_ENTITY,
} from "../../../../consts/TextConstants";
import AddEntity from "./AddEntity";

const EntityEditPage = ({ ...props }) => {
  const { currentEditForm } = useSelector((state) => state.entity);
  const [formTitle, setFormTitle] = useState(LBL__ENTITY_INFO);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleChangeFormTitle = (title) => {
    setFormTitle(title);
  };

  let editForm = (
    <EditPrimaryEntityDetails
      changeFormTitle={handleChangeFormTitle}
      {...props}
    />
  );

  let newFormTitle = LBL__ENTITY_INFO;
  let subFormTitle = <></>;

  switch (currentEditForm) {
    case entityEditForms.REGISTRATION:
      newFormTitle = TITLE__ENTITY_INFORMATION;
      subFormTitle = (
        <span className={styles.sub_title}>- {props.entityName}</span>
      );
      break;

    case entityEditForms.IRS:
      newFormTitle = LBL__IRS_INFO;
      break;

    case entityEditForms.AGENT:
      newFormTitle = LBL__AGENT_INFO;
      break;

    case entityEditForms.FOREIGN:
      editForm = (
        <AddForeignState
          changeFormTitle={handleChangeFormTitle}
          {...props}
        />
      );
      newFormTitle = LBL__ADD_FOREIGN_STATE;
      break;

    case entityEditForms.BOARD_DIRECTORS:
    case entityEditForms.BOARD_OFFICERS:
      newFormTitle =
        currentEditForm == entityEditForms.BOARD_OFFICERS
          ? LBL__ADD_OFFICER
          : LBL__ADD_DIRECTOR;
      editForm = <AddBoardDetails editForm={currentEditForm} {...props} />;
      break;

    case entityEditForms.ADD_ENTITY:
      editForm = <AddEntity {...props} />;
      newFormTitle = TITLE__ADD_ENTITY;
      break;

    default:
      break;
  }

  useEffect(() => {
    setFormTitle(newFormTitle);
  }, []);

  return (
    <EditOverlay
      title={formTitle}
      className={props.className}
      onClose={props.onClose}
      sub_text={subFormTitle}
    >
      {editForm}
    </EditOverlay>
  );
};

export default EntityEditPage;
