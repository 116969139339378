import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authActions } from "./auth-slice";
import { BASE_URL, BASE_URL_COMPLIANCE } from "../consts/APIConstants";
import { logoutUser } from "../lib/auth";

const createBaseQuery = (baseUrl) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      // const token = getState().auth.token;
      // if (token) {
      //   headers.set("Authorization", `Bearer ${token}`);
      // }
      // headers.set("Access-Control-Allow-Origin", "*");
      // headers.set("Content-type", "application/json");
      // headers.set("rid", "anti-csrf");
      return headers;
    },
  });

const mainBaseQuery = createBaseQuery(BASE_URL);
const secondaryBaseQuery = createBaseQuery(BASE_URL_COMPLIANCE);

// const baseQueryWithReauth1 = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);

//   if (result?.error?.originalStatus == 403) {
//     //send refresh token
//     const refreshResult = await baseQuery("refresh", api, extraOptions);
//     if (refreshResult?.data) {
//       const user = api.getState().auth.user;

//       //store the new token
//       api.dispatch(authActions.setCredentials({ ...refreshResult.data, user }));

//       //retry the original query with new access token
//       result = await baseQuery(args, api, extraOptions);
//     } else {
//       api.dispatch(authActions.logout());
//     }
//   }
//   return result;
// };

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const baseQueryToUse =
    extraOptions?.endpoint === "compliance"
      ? secondaryBaseQuery
      : mainBaseQuery;

  let result = await baseQueryToUse(args, api, extraOptions);
  if (result?.error?.status == 401) {
    // api.dispatch(authActions.logout());
    await logoutUser();
    window.location.href = "/";
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
