import { useSelector } from 'react-redux';
import { useGetDocumentsQuery } from './apis/documentsApi-slice';
import styles from './DocumentsList.module.css';
import DocumentItem from './DocumentItem';
import { Link } from 'react-router-dom';
import arrowUpBlack from '/assets/common/ArrowUp_Black.svg';

const DocumentsList = ({ entityName, entityId }) => {
    // Fetch documents from Redux store
    const { documents } = useSelector((state) => state.documents);

    // Fetch documents via API (This is handled automatically in the reducer now)
    const { error: apiError, isLoading } = useGetDocumentsQuery();

    if (apiError) {
        return <div className={styles.error}>Failed to fetch documents.</div>;
    }
    if (isLoading) {
        return <div>Loading...</div>;
    }

    const displayDocuments = documents[entityId] || [];

    return (
        <div className={styles.documents_container}>
            <Link to={`/home/entities/${entityId}`} className={styles.entity_name_link}>
                {entityName}
                <img src={arrowUpBlack} className={styles.arrow_up_black} alt="Expand" />
            </Link>

            <div className={styles.files_container}>
                {displayDocuments.length > 0 ? (
                    displayDocuments.map((fileName, index) => (
                        <DocumentItem
                            key={index}
                            fileName={fileName}
                            entityId={entityId}
                        />
                    ))
                ) : (
                    <p></p>
                )}
            </div>
        </div>
    );
};

export default DocumentsList;



