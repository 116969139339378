import { useState } from "react";
import { useSelector } from "react-redux";
import { FlagEmoji } from "react-international-phone";
import styles from "./ForeignShareholders.module.css";
import AddNewDetailButton from "../../ui-compliance/AddNewDetailButton";
import MoreInfoButton from "../../ui-compliance/MoreInfoButton";
import InfoBox from "../../ui-compliance/InfoBox";
import { countriesLookup, convertNumbersToShorts } from "../../../../../utils/common-utils";
import sharePercentageIcon from "/assets/compliance/Chart_Pie.svg";
import { complianceEditForms } from "../../../../../consts/Constants";
import EditOverlay from "./EditForms/EditOverlay";
import { useGetForeignShareHoldersQuery } from "../../apis/complianceApi-slice";

const ForeignShareholder = ({ foreignShareholder, onExit }) => {
  const country = countriesLookup(foreignShareholder.country).key;

  return (
    <div className={styles.foreign__subsidiary}>
      <div className={styles.name}>{foreignShareholder.name}</div>
      <InfoBox className={styles.percentage}>
        <img src={sharePercentageIcon} alt="" />
        {foreignShareholder.percentage}%
      </InfoBox>
      <div className={styles.shareholder_details_wrapper}>
        <div className={styles.shareholder_details}>
          <InfoBox className={styles.country}>
            <FlagEmoji iso2={country} className={styles.img_flag} />
            {foreignShareholder.country}
          </InfoBox>
          <InfoBox>
            {convertNumbersToShorts(foreignShareholder.noOfShares)}
          </InfoBox>
        </div>
        {foreignShareholder?.is5472Applicable && (
          <InfoBox infostyle="light-violet">{foreignShareholder?.formName}</InfoBox>
        )}
      </div>
      {/* <MoreInfoButton
        className={styles.btn__more_info}
        name={foreignShareholder.entityName}
        onSelect={onExit}
      /> */}
    </div>
  );
};

const ForeignShareholders = () => {
  const { foreignShareHolders, selectedEntity, complianceDate } = useSelector((state) => state.compliance);
  const [editOverlay, setEditOverlay] = useState({ show: false, formName: "" });

  // const { isLoading: isForeignShareHoldersLoading } = useGetForeignShareHoldersQuery({
  //   entity_id: selectedEntity?.entityId,
  // });

  const handleExit = (state) => {
    setEditOverlay({
      show: true,
      formName: complianceEditForms.REMOVE_SHAREHOLDER,
      foreignState: state,
    });
  };

  const handleAddForeignShareholder = () => {
    setEditOverlay({
      show: true,
      formName: complianceEditForms.FOREIGN_SHAREHOLDERS,
    });
  };

  const handleCloseEditOverlay = () => {
    setEditOverlay({ show: false, formName: "" });
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Confirm foreign shareholders holding more than 25% stake in the entity
      </div>

      <div className={styles.content}>
        <AddNewDetailButton
          label="Add Foreign shareholder details"
          className={styles.btn__add}
          onClick={handleAddForeignShareholder}
        />
        {foreignShareHolders.map((shareholder) => (
          <ForeignShareholder
            key={shareholder.name}
            foreignShareholder={shareholder}
            onExit={handleExit}
          />
        ))}
      </div>
      {editOverlay.show && (
        <EditOverlay
          onClose={handleCloseEditOverlay}
          formName={editOverlay.formName}
          className={
            editOverlay.formName === complianceEditForms.FOREIGN_SHAREHOLDERS
              ? styles.add_overlay
              : styles.remove_overlay
          }
          incorporationDate={selectedEntity?.incorporationDate}
          complianceDate={complianceDate}
          entityId={selectedEntity?.entityId}
        />
      )}
    </div>
  );
};

export default ForeignShareholders;
