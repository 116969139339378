import { Outlet, useOutletContext } from "react-router-dom";
import { useLocation, Navigate } from "react-router-dom";
import HomePage from "../components/Home/HomePage";
import LoadingComponent from "../components/UI/LoadingIndicator/LoadingComponent";

const RequiredAuth = () => {
  const { session } = useOutletContext();
  const location = useLocation();

  if (session?.isValidSession == null) {
    return <LoadingComponent />;
  }

  return session?.isValidSession ? (
    <HomePage>
      <Outlet />
    </HomePage>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequiredAuth;
