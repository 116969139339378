import styles from "./ConfirmedDetails.module.css";
import dataConfirmationIcon from "/assets/compliance/Data_Confirmation.svg";

const ConfirmedDetails = () => {
  return (
    <div className={styles.container}>
      <img src={dataConfirmationIcon} alt="Data Confirmation" />
      <div className={styles.text_container}>
        <p>YOUR COMPLIANCE LIST IS GENERATED</p>
        <p className={styles.blue_text}>COMPLETE PAYMENT TO GET STARTED</p>
      </div>
    </div>
  );
};

export default ConfirmedDetails;
