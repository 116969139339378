import { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ComplianceFilter.module.css";
import Filter from "../../UI/Filter/Filter";
import SearchBar from "../../UI/Input/SearchBar";
import { complianceFiltersActions } from "./reducers/complianceFilters-slice";
import { useGetAllCompliancePeriodsQuery } from "./apis/complianceApi-slice";

const ComplianceFilter = ({ loadFromState }) => {
  const { primaryEntity, entities } = useSelector((state) => state.entity);
  const {
    complianceYear,
    complianceFormattedPeriod,
    complianceEntityName,
    complianceEntity,
    compliancePeriodsList,
  } = useSelector((state) => state.complianceFilters);

  const dispatch = useDispatch();

  const { isLoading } = useGetAllCompliancePeriodsQuery(
    { entity_id: complianceEntity?.entityId },
    {
      skip: !complianceEntity?.entityId,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (compliancePeriodsList[String(complianceYear)]?.length > 0) {
      dispatch(
        complianceFiltersActions.setCompliancePeriod(
          compliancePeriodsList[String(complianceYear)][0]
        )
      );
    }
  }, [compliancePeriodsList, complianceYear]);

  const entityOptions = useMemo(
    () =>
      entities?.reduce((acc, entity) => {
        if (entity.country === "United States") {
          acc[entity.entityName] = entity.entityId;
        }
        return acc;
      }, {}),
    [entities]
  );

  const yearOptions = useMemo(() => {
    if (!entities || !complianceEntityName) return [];

    const currentYear = new Date().getFullYear();

    const incorporationYear = new Date(
      entities.find(
        (e) => e.entityName === complianceEntityName
      )?.incorporationDate
    ).getFullYear();

    const currentMonth = new Date().getMonth();
    const compYear =
      currentMonth < 8 && incorporationYear < currentYear
        ? currentYear - 1
        : currentYear;

    return Array.from({ length: compYear - incorporationYear + 1 }, (_, i) =>
      (compYear - i).toString()
    );
  }, [entities, entityOptions, complianceEntityName]);

  useEffect(() => {
    if (!loadFromState) {
      dispatch(
        complianceFiltersActions.setComplianceEntity({
          ...primaryEntity,
          state: primaryEntity?.incorporationState,
          country: primaryEntity?.incorporationCountry,
        })
      );
    }
  }, []);

  const handlePeriodSelect = (value) => {
    dispatch(complianceFiltersActions.setCompliancePeriod(value));
  };

  const handleYearSelect = (value) => {
    dispatch(complianceFiltersActions.setComplianceYear(value));
  };

  const handleEntitySelect = (value) => {
    const entity = entities.find((entity) => entity.entityName === value);
    dispatch(complianceFiltersActions.setComplianceEntity(entity));
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {/* <SearchBar
          id="input-search"
          placeholder="Search"
          inputstyle={`${styles.searchbar} ${styles.disabled}`}
          onChange={() => {}}
          disabled={true}
        /> */}
      </div>
      <div className={styles.right}>
        <Filter
          labelTitle="Entity"
          options={Object.keys(entityOptions)}
          onSelect={handleEntitySelect}
          dropdownSize={styles.dropdown_entity}
          value={complianceEntityName}
        />
        <Filter
          labelTitle="Year"
          options={yearOptions}
          onSelect={handleYearSelect}
          dropdownSize={styles.dropdown_year}
          value={complianceYear}
        />
        <Filter
          labelTitle="Period"
          options={compliancePeriodsList[String(complianceYear)] || []}
          onSelect={handlePeriodSelect}
          dropdownSize={styles.dropdown_period}
          value={complianceFormattedPeriod}
          // disabled={true}
        />
      </div>
    </div>
  );
};

export default ComplianceFilter;
