export const PAYLD__CREATE_ENTITY = {
  brandName: "brand_name",
  entityName: "entity_name",
  entityStructure: "entity_structure",
  incorporationState: "incorporation_state",
  incorporationDate: "incorporation_date",
  entityType: "entity_type",
  isPrimary: "isPrimary",
  otherStateValue: "has_other_state",
  standalone: "has_other_entity",
};

export const PAYLD__UPDATE_ENTITY = {
  einNumber: "ein_number",
  currentTaxYear: "current_tax_year",
  currentTaxForm: "current_tax_form",
  entityName: "entity_name",
  entityId: "entity_id",
  stateId: "state_id",
  companyEntityId: "company_entity_id",
  entityType: "entity_type",
  entityStructure: "entity_structure",
  authorizedShares: "authorized_shares",
  parValue: "par_value",
  parentPercentage: "perc_owned_by_parent",
  parentShares: "shares_owned_by_parent",
  incorporationState: "incorporation_state",
  incorporationDate: "incorporation_date",
  subsidizationDate: "subsidization_date",
  businessAddressStreet: "business_address_street",
  businessAddressCity: "business_address_city",
  businessAddressState: "business_address_state",
  businessAddressZip: "business_address_zip",
  businessAddressCountry: "business_address_country",
  agentName: "registered_agent_name",
  agentAddressStreet: "registered_agent_street",
  agentAddressCity: "registered_agent_city",
  agentAddressState: "registered_agent_state",
  agentAddressZip: "registered_agent_zip",
  agentAddressCountry: "registered_agent_country",
  isRegistrationEditable: "isRegistrationEditable",
  isIrsEditable: "isIrsEditable",
  isRegisterAgentEditable: "isRegisterAgentEditable",
  parent: "parent_entity_id",
  isSubsidiary: "isSubsidiary",
  incorporationCountry: "incorporation_country",
};

export const PAYLD__CREATE_FOREIGN_STATE = {
  stateId: "foreign_state_id",
  entityName: "entity_name",
  entityId: "entity_id",
  registrationDate: "foreign_state_registration_date",
  businessAddressStreet: "foreign_business_address_street",
  businessAddressCity: "foreign_business_address_city",
  businessAddressState: "foreign_business_address_state",
  businessAddressZip: "foreign_business_address_zip",
  businessAddressCountry: "foreign_business_address_country",
  agentName: "foreign_registered_agent_name",
  agentAddressStreet: "foreign_registered_agent_street",
  agentAddressCity: "foreign_registered_agent_city",
  agentAddressState: "foreign_registered_agent_state",
  agentAddressZip: "foreign_registered_agent_zip",
  agentAddressCountry: "foreign_registered_agent_country",
  foreignState: "foreign_state",
  nexusTypes: "foreign_nexus_type[]",
  isRegisteredInState: "isRegisteredInState",
  state: "state",
};

export const PAYLD__BOARD_MEMBER = {
  entityId: "entity_id",
  entityName: "entity_name",
  title: "board_member_title",
  isOfficer: "isOfficer",
  isDirector: "isDirector",
  name: "board_member_name",
  street: "board_business_street",
  city: "board_business_city",
  state: "board_business_state",
  zip: "board_business_zip",
  country: "board_business_country",
};

export const PAYLD__CREATE_SHARE_HOLDER = {
  entityId: "entity_id",
  entityName: "entity_name",
  shareHolder: "shareholder_name",
  citizenship: "shareholder_citizenship",
  forYear: "shareholder_as_on",
  shareType: "share_type",
  issuedDate: "shareholder_issued_date",
  noOfShares: "shareholder_no_of_shares",
  ownership: "shareholder_ownership_perc",
  stakeType: "shareholder_type",
  parValue: "share_par_value",
  street: "shareholder_street",
  city: "shareholder_city",
  state: "shareholder_state",
  zip: "shareholder_zip",
  country: "shareholder_country",
  amount: "share_safe_amount",
  strikePrice: "share_strike_price",
};
