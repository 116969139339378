import { PUBLIC_URL } from "../../../consts/Constants";

export const getImageURL = (path) => {
  return new URL(path, PUBLIC_URL).href;
};

export const navMenuItems = {
  DASHBOARD: "Home",
  COMPLIANCE: "Compliance",
  CAPTABLE: "CAP Table",
  FINANCE: "Finance",
  ENTITTIES: "Entities",
  SERVICES: "Services",
  SUBSCRIPTIONS: "Billing",
  CHAT: "Chat",
  DOCUMENTS: "Documents",
  NEEDHELP: "Need Help?",
  TEAM: "Team",
  FEEDBACK: "Feedback",
  SUPPORT: "Support",
};

export const navMenuFeatureItemsList = {
  home: {
    title: navMenuItems.DASHBOARD,
    icon: getImageURL("/assets/sidemenu/Home_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Home_Blue.svg"),
    path: "/home",
  },
  compliance: {
    title: navMenuItems.COMPLIANCE,
    icon: getImageURL("/assets/sidemenu/Compliance_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Compliance_Blue.svg"),
    path: "/home/compliance",
  },
  finance: {
    title: navMenuItems.FINANCE,
    icon: getImageURL("/assets/sidemenu/Finance_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Finance_Blue.svg"),
    path: "/home/finance",
  },
  services: {
    title: navMenuItems.SERVICES,
    icon: getImageURL("/assets/sidemenu/Services_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Services_Blue.svg"),
    path: "/home/services",
  },
  entities: {
    title: navMenuItems.ENTITTIES,
    icon: getImageURL("/assets/sidemenu/Entities_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Entities_Blue.svg"),
    path: "/home/entities",
  },
};

export const navMenuInfoItemsList = {
  invoicing: {
    title: navMenuItems.SUBSCRIPTIONS,
    icon: getImageURL("/assets/sidemenu/Billing_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Billing_Blue.svg"),
    path: "/home/subscriptions",
    disabled: true,
  },
  documents: {
    title: navMenuItems.DOCUMENTS,
    icon: getImageURL("/assets/sidemenu/Documents_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Documents_Blue.svg"),
    path: "/home/documents",
    // disabled: true,
  },
  team: {
    title: navMenuItems.TEAM,
    icon: getImageURL("/assets/sidemenu/Team_Gray.svg"),
    iconActive: getImageURL("/assets/sidemenu/Team_Blue.svg"),
    path: "/home/team",
    disabled: true,
  },
  support: {
    title: navMenuItems.SUPPORT,
    icon: getImageURL("/assets/sidemenu/Support.svg"),
  },
};

export const navGeneralFeaturesList = {
  documents: {
    title: navMenuItems.DOCUMENTS,
    icon: getImageURL("/assets/sidemenu/Documents.svg"),
    path: "/home/documents",

  },
  needHelp: {
    title: navMenuItems.NEEDHELP,
    icon: getImageURL("/assets/sidemenu/NeedHelp.svg"),
    path: "/home/needhelp",
  },
};

export const navMenuOtherItemsList = {
  feedback: {
    title: navMenuItems.FEEDBACK,
    icon: getImageURL("/assets/sidemenu/Feedback.svg"),
  },
};
