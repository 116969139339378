import { useState } from "react";
import styles from "./RemoveItem.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import Button from "../../../../../UI/Button/Button";

const RemoveItem = ({ label, onSubmit, loading = false }) => {
  const [registrationDate, setRegistrationDate] = useState(new Date());

  return (
    <div className={styles.container}>
      <div className={styles.form_inputs}>
        <EditInputItem
          name={label}
          type="datepicker"
          labelTitle={label}
          onChange={(value) => setRegistrationDate(value)}
          value={registrationDate}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={onSubmit}
        buttonstyle="default-blue"
        loading={loading}
        disabled={loading}
      >
        Submit
      </Button>
    </div>
  );
};

export default RemoveItem;
