import { useSelector } from "react-redux";
import { FlagEmoji } from "react-international-phone";
import styles from "./ComplianceTasksList.module.css";
import Collapsible from "../../UI/Collapsible/Collapsible";
import MoreInfoButton from "./ui-compliance/MoreInfoButton";
import InfoBox from "./ui-compliance/InfoBox";
import { countriesLookup } from "../../../utils/common-utils";
import Button from "../../UI/Button/Button";
import arrowIcon from "/assets/common/ArrowRight_White.svg";
import arrowGrayIcon from "/assets/common/ArrowRight_LightGray.svg";

const TaskCard = ({ task, entity }) => {
  const countryCode = countriesLookup(entity?.country).key;

  return (
    <div
      className={`${styles.task_card} ${
        task?.taskStatus?.toLowerCase() === "action required"
          ? styles.action_required
          : ""
      }`}
    >
      <MoreInfoButton
        className={styles.btn__more_info}
        onSelect={() => {}}
        options={[{ name: "Email us", icon: "/assets/common/Email_Black.svg" }]}
      />
      <div className={styles.task_content}>
        <div className={styles.task_form}>{task.name}</div>
        <div className={styles.task_ticket_number}>{task.ticketNumber}</div>
        <div
          className={`${styles.task_action_status} ${
            styles[task?.taskStatus?.toLowerCase()?.replace(/\s+/g, "")]
          }`}
        >
          {task?.taskStatus}
        </div>
      </div>
      <div className={styles.task_footer}>
        <InfoBox className={styles.entity_name}>
          <FlagEmoji iso2={countryCode} className={styles.img_flag} />
          {entity?.entityName}
        </InfoBox>
        {task.paymentStatus === "pending" && (
          <Button className={styles.btn__pay} buttonstyle="default-blue">
            <span>PAY ${task.price}</span>
            <img src={arrowIcon} alt="" />
          </Button>
        )}
        {/* {task.paymentStatus === "paid" && task.taskStatus !== "" && (
          <Button className={styles.btn__go}>
            <img src={arrowGrayIcon} alt="" />
          </Button>
        )} */}
        {task.quantity && (
          <div className={styles.task_quantity}>
            <span>{task.quantity}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const ComplianceTasksList = () => {
  const { complianceTasks, selectedEntity } = useSelector(
    (state) => state.compliance
  );
  const { complianceEntity } = useSelector((state) => state.complianceFilters);
  return (
    <div className={styles.container}>
      {Object.keys(complianceTasks).map((taskType) => (
        <Collapsible
          key={taskType}
          title={taskType}
          contentstyle={styles.collapsible_content}
        >
          {complianceTasks[taskType].map((task, index) => (
            <TaskCard
              key={`${task.form}-${index}`}
              task={task}
              entity={complianceEntity}
            />
          ))}
        </Collapsible>
      ))}
    </div>
  );
};

export default ComplianceTasksList;
