import { useState, useMemo } from "react";
import { FlagEmoji } from "react-international-phone";
import styles from "./DocumentsEntityList.module.css";
import arrowRightGray from "/assets/common/ArrowRight_Grey.svg";
import arrowRightBlue from "/assets/common/ArrowRight_Blue.svg";
import { countriesLookup } from "../../../utils/common-utils";
import SearchBar from "../../UI/Input/SearchBar";

const DocumentsEntityList = ({ entities, selectedListItem, onSelect }) => {
    const [searchValue, setSearchValue] = useState("");

    const handleOnSelect = (entity) => {
        if (entity.isBasicComplete || entity.isFullComplete) {
            onSelect(true, entity);
        }
    };

    const filteredEntities = useMemo(() => {
        return entities.filter((entity) =>
            entity.entityName.toLowerCase().includes(searchValue.toLowerCase())
        );
    }, [entities, searchValue]);

    return (
        <div className={styles.entity_ul_list_container}>
            <div className={styles.fixed_searchbar}>
                <SearchBar
                    id="input-search"
                    placeholder="Search"
                    onChange={setSearchValue}
                    inputstyle={styles.searchbar}
                />
            </div>
            <ul className={styles.entity_list_items}>
                {filteredEntities.map((entity, index) => {
                    const isItemSelected = selectedListItem?.entityId === entity.entityId;
                    const statusClass = `${entity.isFullComplete
                        ? styles.completed
                        : entity.isBasicComplete
                            ? styles.pending
                            : styles.disabled
                        } ${isItemSelected ? styles.selected : ""}`;

                    return (
                        <li
                            key={`item-${index}`}
                            onClick={() => handleOnSelect(entity)}
                            className={`${styles.list_item} ${statusClass}`}
                        >
                            <div className={styles.entity_details}>
                                <p className={`${styles.status} ${statusClass}`} />
                                <div className={styles.entity_name}>
                                    <p >{entity.entityName}</p>
                                    <FlagEmoji
                                        iso2={countriesLookup(entity.country)?.key}
                                        className={styles.img_flag}
                                    />
                                </div>

                            </div>
                            {(entity.isBasicComplete) && (
                                <div className={`${styles.arrow_container} ${statusClass}`}>
                                    <img
                                        src={isItemSelected ? arrowRightBlue : arrowRightGray}
                                        className={styles.arrow}
                                    />
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default DocumentsEntityList;

