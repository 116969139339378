import { useFetchDocumentMutation } from './apis/documentsApi-slice';
import styles from './DocumentItem.module.css';
import fileView from '/assets/common/FileView_Icon.svg';
import { handleDownloadedFile } from '../../../utils/fileHandler';
import {
    documentFileMappings
} from '../../../consts/TextConstants';
import MoreInfo from "../../UI/General/MoreInfo";

const mapFileName = (fileName) => {
    const lowerCaseFileName = fileName.toLowerCase();
    for (let key in documentFileMappings) {
        if (lowerCaseFileName.includes(key)) {
            return documentFileMappings[key];
        }
    }
    return fileName; // If no match, return the original file name.
};

const DocumentItem = ({ fileName, entityId }) => {
    // useFetchDocumentMutation hook is used to call the API to fetch the document.
    const [fetchDocument] = useFetchDocumentMutation();

    //function that is triggered when the user clicks on the component to download the document.
    const handleDownload = async () => {
        // Early return if either fileName or entityId is not provided (ensures that download only happens if valid params are passed).
        if (!fileName || !entityId) return;

        try {
            // Fetch the document as a blob using the fetchDocument mutation.
            const blob = await fetchDocument({ entity_id: entityId, file_name: fileName }).unwrap();

            //handleDownloadedFile utility create a downloadable file from the received blob data and trigger the download action.
            handleDownloadedFile(blob, fileName);

        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        // The component rendered as a clickable container. When clicked,handleDownload function is triggered.
        <MoreInfo
            key={`moreinfo-${fileName}`}
            content={fileName}
            position="top"
            showMoreInfoIcon={false}
            customContainerComponent={true}
        >
            <div className={styles.container} onClick={handleDownload}>

                <img src={fileView} className={styles.folder_img} alt="" />
                <p className={styles.file_name} title={fileName}>
                    {mapFileName(fileName)}
                </p>
            </div>
        </MoreInfo>
    );
};

export default DocumentItem;
