import {
  METHOD_GET,
  METHOD_POST,
  URL__GET_ALL_SERVICES,
  URL__POST_SERVICES,
  URL__GET_ALL_SUBSCRIBED_SERVICES,
  URL__GET_BANK_ACCOUNT,
  URL__POST_GST_DETAILS,
  URL__PAYMENT_UPLOAD_RECIPT,
  URL__SERVICES_SUPPORT_EMAIL,
  URL__UPDATE_TASK_PHASE,
  URL__UPLOAD_SIGNED_TRC,
  URL__GET_TRC_YEARS,
  URL__CREATE_QUOTE_SERVICE,
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { serviceActions } from "../reducers/service-slice";

const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      // get services from database list of services and Desc & Price in serviceList
      query: (args) => ({
        url: URL__GET_ALL_SERVICES,
        method: METHOD_GET,
        headers: { Accept: "*/*", "Content-Type": "application/json" },
        params: args,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(serviceActions.setServices(data.services));
        } catch (error) {
          console.error("Error fetching services:", error);
        }
      },
    }),

    addServices: builder.mutation({
      // After clicking Pay button only sends to selected serivce name desc price ServicesAdded comp year and entity id is sending..
      query: (data) => ({
        url: URL__POST_SERVICES,
        method: METHOD_POST,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: data,
      }),
    }),

    getAllUserServices: builder.query({
      // inside services page table i want to show that selected services still pending in AddedServices List
      query: (args) => ({
        url: URL__GET_ALL_SUBSCRIBED_SERVICES,
        method: METHOD_GET,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        params: args,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(serviceActions.setAllServices(data));
        } catch (error) {
          dispatch(
            serviceActions.setAllServices({ subscribed_services: [], total: 0 })
          );
          console.error("Error fetching all subscribed services:", error);
        }
      },
    }),

    getBankAccounts: builder.query({
      query: () => ({
        url: URL__GET_BANK_ACCOUNT,
        method: METHOD_GET,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(serviceActions.setBankAccounts(data));
        } catch (error) {
          console.error("Error fetching bank accounts:", error);
        }
      },
    }),

    submitGstDetails: builder.mutation({
      query: (gstDetails) => ({
        url: URL__POST_GST_DETAILS,
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gstDetails),
      }),
    }),

    uploadReceipt: builder.mutation({
      query: (args) => ({
        url: URL__PAYMENT_UPLOAD_RECIPT,
        method: "POST",
        header: {
          Accept: "/",
          "Accept-Encoding": "gzip, deflate",
          "content-type": "multipart/form-data",
        },
        params: args.params,
        body: args.data,
      }),
    }),

    // In your serviceApi-slice.js
    sendSupportEmail: builder.mutation({
      query: (args) => ({
        url: URL__SERVICES_SUPPORT_EMAIL,
        method: METHOD_POST,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        params: args.params,
        body: args.data, // Body contains subject and body
      }),
    }),

    updateTaskPhase: builder.mutation({
      query: (data) => ({
        url: URL__UPDATE_TASK_PHASE,
        method: METHOD_POST,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: data,
      }),
    }),

    uploadSignedTrc: builder.mutation({
      query: (args) => ({
        url: URL__UPLOAD_SIGNED_TRC,
        method: METHOD_POST,
        header: {
          Accept: "/",
          "Accept-Encoding": "gzip, deflate",
          "content-type": "multipart/form-data",
        },
        params: args.params,
        body: args.data,
      }),
    }),
    getTRCYears: builder.query({
      query: (args) => ({
        url: URL__GET_TRC_YEARS + args.entityId,
        method: METHOD_GET,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }),
    }),
    createQuoteService: builder.mutation({
      query: (data) => ({
        url: URL__CREATE_QUOTE_SERVICE,
        method: METHOD_POST,
        body: data,
      }),
    }),
  }),
});

export const {
  useGetServicesQuery,
  useAddServicesMutation,
  useGetAllUserServicesQuery,
  useLazyGetServicesQuery,
  useLazyGetAllUserServicesQuery,
  useGetBankAccountsQuery,
  useSubmitGstDetailsMutation,
  useUploadReceiptMutation,
  useSendSupportEmailMutation, // Export the new hook
  useUpdateTaskPhaseMutation,
  useUploadSignedTrcMutation,
  useLazyGetTRCYearsQuery,
  useCreateQuoteServiceMutation,
} = servicesApiSlice;
