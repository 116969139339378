import { defaultCountries } from "react-international-phone";
import { MONTH_NAMES_SHORT } from "../consts/Constants";

export const getNumberOfDaysInAMonth = (month, year) => {
  if (month == 2) {
    if (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) {
      return 29;
    }
    return 28;
  } else if ([4, 6, 9, 11].includes(month)) {
    return 30;
  }
  return 31;
};

export const getNumberOfDaysInPreviousMonth = (month, year) => {
  if (month == 1) {
    return getNumberOfDaysInAMonth(12, year - 1);
  }
  return getNumberOfDaysInAMonth(month - 1, year);
};

export const getWeekDayOfFirstDayOfAMonth = (inputDate) => {
  const date = new Date(inputDate);
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstDay = new Date(year, month, 1);
  return firstDay.getDay();
};

export const splitDate = (date) => {
  // if(!date) {
  //   return null;
  // }
  const day = date?.getDate();
  const month = date?.getMonth() + 1;
  const year = date?.getFullYear();

  return { day, month, year };
};

export const toTwoDigitFormat = (value) => {
  return value.toString().padStart(2, "0");
};

//converts date string to iso date
export const convertToISODate = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

//converts iso date to string in format mmm dd, yyyy
export const convertISODateToStr = (isoDate) => {
  if (!isoDate) return null;
  const date = new Date(isoDate);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
};

export const getStringFromDateInFormat = (
  inputDate,
  format,
  convertInputDate = false
) => {
  if (!inputDate) {
    return null;
  }
  const convertedInputDate = convertInputDate
    ? convertDateString(inputDate)
    : inputDate;

  const date =
    typeof convertedInputDate == Date
      ? convertedInputDate
      : new Date(convertedInputDate);

  const { day, month, year } = splitDate(date);

  if (format?.toLowerCase() == "mm/dd/yyyy") {
    return `${toTwoDigitFormat(month)}/${toTwoDigitFormat(day)}/${year}`;
  }
  if (format?.toLowerCase() == "mmm dd, yyyy") {
    return `${MONTH_NAMES_SHORT[month - 1]} ${toTwoDigitFormat(day)}, ${year}`;
  }
  if (format?.toLowerCase() == "dd mmm yyyy") {
    return `${toTwoDigitFormat(day)} ${MONTH_NAMES_SHORT[month - 1]} ${year}`;
  }
  if (format?.toLowerCase() == "yyyy-mm-dd") {
    return `${year}-${toTwoDigitFormat(month)}-${toTwoDigitFormat(day)}`;
  }
  return `${toTwoDigitFormat(day)}/${toTwoDigitFormat(month)}/${year}`;
};

export const getDateFromString = (dateStr, dateFormat = "DD/MM/YYYY") => {
  const splitChar = dateFormat == "DD MMM YYYY" ? "/" : "/";
  const dateParts = dateStr.split(splitChar);

  return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
};

//converts date string in format dd/mm/yyyy to date string in format mm/dd/yyyy
export const convertDateString = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  const dateParts = dateStr.split("/");

  return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
};

export const getCountriesList = () => {
  let countryList = [
    { value: "United States", key: "us" },
    { value: "India", key: "in" },
  ];

  defaultCountries.forEach((item) => {
    if (item[1] != "us" && item[1] != "in") {
      countryList.push({ value: item[0], key: item[1] });
    }
  });

  return countryList;
};

export const countriesLookup = (countryValue) => {
  if (!countryValue) return null;

  const countriesList = getCountriesList();
  return countriesList.find(
    (obj) => obj.value == countryValue || obj.key == countryValue
  );
};

export const getYearFromDateString = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  const dateParts = dateStr.split("/");

  return dateParts[2];
};

export const convertNumbersToShorts = (numValue) => {
  let num = parseInt(numValue);
  if (num >= 1000 && num < 1000000) {
    return `${num / 1000}K`;
  } else if (num >= 1000000 && num < 1000000000) {
    return `${num / 1000000}M`;
  } else if (num >= 1000000000) {
    return `${num / 1000000000}B`;
  } else {
    return `${num}`;
  }
};

export const convertShortsToNumbers = (strValue) => {
  let lastChar = strValue.slice(-1);
  let num = 0;
  switch (lastChar) {
    case "K":
      num = parseInt(strValue.split("K")) * 1000;
      break;
    case "M":
      num = parseInt(strValue.split("M")) * 1000000;
      break;
    case "B":
      num = parseInt(strValue.split("B")) * 1000000000;
      break;
    default:
      num = parseInt(strValue);
      break;
  }
  return num;
};

export const combineAddressString = (street, city, state, zipCode, country) => {
  return `${street}, ${city}, ${state}, ${country} - ${zipCode}`;
};

export const getFullFileName = (fileName) => {
  return fileName.split(".").slice(0, -1).toString();
};

export const getShortFileName = (fileName) => {
  const fullName = getFullFileName(fileName);
  if (fullName.length < 5) {
    return fullName;
  }
  return `${fullName.slice(0, 2)}...${fullName.slice(-2)}`;
};

export const getFileExtension = (fileName) => {
  return fileName.split(".").slice(-1).toString();
};

export const addNumberOfDaysToDate = (currentDate, noOfDays = 0) => {
  const newDate = new Date(currentDate);
  return new Date(newDate.setDate(newDate.getDate() + noOfDays));
};

export const addNumberOfMonthsToDate = (currentDate, noOfMonths = 0) => {
  const newDate = new Date(
    currentDate.setMonth(currentDate.getMonth() + noOfMonths)
  );
  return `${MONTH_NAMES_SHORT[newDate.getMonth()]} ${newDate.getFullYear()}`;
};

export const getCurrencyWithSymbol = (currencyCode, amount = 0) => {
  if (amount == undefined || amount == null) {
    return "";
  }

  return Intl.NumberFormat("en", {
    currency: currencyCode,
    style: "currency",
  }).format(amount);
};

export const partitionArray = (arr, fn) => {
  return arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[], []]
  );
};

export const formatPeriod = (year, period = "Jan - Dec") => {
  const shortYear = year?.toString()?.slice(-2);

  const periods = period.split("-");
  const from = periods[0]?.trim();
  const to = periods[1]?.trim();

  const fromMonth = from.slice(-3);
  const toMonth = to.slice(-3);

  if (MONTH_NAMES_SHORT.indexOf(fromMonth) > MONTH_NAMES_SHORT.indexOf(toMonth)) {
    return `${from} '${shortYear} - ${to} '${Number(shortYear) + 1}`;
  }
  return `${from} '${shortYear} - ${to} '${shortYear}`;
};
