import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInput from "../../../../hooks/use-input";
import EditFormInputs from "../../Edit/EditFormInputs";
import EditInputItem from "../../Edit/EditInputItem";
import EditFormActionGroup from "../../Edit/EditFormActionGroup";
import styles from "./EditRegistrationDetails.module.css";
import {
  LBL__SUBSIDIZATION_DATE,
  BTN__RETRY,
  LBL__PARENT,
  BTN__SUBMIT,
  BTN__NEXT,
  BTN__BACK,
  LBL__IS_SUBSIDIARY,
  LBL__INCORPORATION_CERTIFICATE,
  LBL__AUTHORIZED_SHARES,
  LBL__PAR_VALUE,
  LBL__STATE_ID,
  LBL__BUSINESS_ADDRESS,
  TITLE__ENTITY_INFORMATION,
  LBL__PARENT_SHARES_PERCENTAGE,
} from "../../../../consts/TextConstants";
import {
  ERROR_MSG__ONLY_NUMBERS,
  ERROR_MSG__INCORPORATION_CERTIFICATE,
  ERROR_MSG__ADD_PARENT,
  ERROR_MSG__PARENT,
  ERROR_MSG__NUMERICAL_VALUES,
  ERROR_MSG__LESS_THAN_100
} from "../../../../consts/ErrorMessages";
import {
  isAlphaNumeric,
  isNotEmpty,
  isNumeric,
  isNonUSCountry,
  isLessThanOrEqualToValue,
} from "../../../../utils/validations";
import {
  getCountriesList,
  convertToISODate,
} from "../../../../utils/common-utils";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import InputCheckBox from "../../../UI/Input/InputCheckBox";
import EditAddressInputs from "../../Edit/EditAddressInputs";
import { useGetParentEntitiesQuery } from "../apis/entityApi-slice";
const countryList = getCountriesList();

const EditRegistrationDetails = ({
  entityId,
  onSubmitData,
  selectedCountry,
  entityType,
  isLoading,
  incorporationDate,
  ...props
}) => {
  const dispatch = useDispatch();

  const [isSubsidiary, setIsSubsidiary] = useState(false);
  const [parent, setParent] = useState("");
  const [subsidizationDate, setSubsidizationDate] = useState(new Date());
  const [error, setError] = useState({ isError: false });
  const [showAddress, setShowAddress] = useState(false);
  const [incorporationCertificate, setIncorporationCertificateInfo] =
    useState(null);

  const [businessAddress, setBusinessAddress] = useState({
    address: "",
    isValid: false,
    addressParams: null,
  });

  const [showParentError, setShowParentError] = useState(false);
  const [
    showIncorporationCertificateError,
    setShowIncorporationCertificateError,
  ] = useState(false);
  const [showBusinessAddressError, setShowBusinessAddressError] =
    useState(false);

  const { entities, primaryEntity, parentEntities } = useSelector(
    (state) => state.entity
  );

  const { isFetching: isLoadingParentEntities } = useGetParentEntitiesQuery({
    entity_id: entityId,
  });

  const shouldUploadIncorporationCertificate = useMemo(() => {
    // If this is the primary entity, only require upload if no certificate exists
    if (primaryEntity?.entityId === entityId) {
      return primaryEntity?.incorporationCertificate == null;
    }

    // For non-primary entities, always require upload
    return true;
  }, [primaryEntity, entityId]);

  const {
    value: stateId,
    isValid: isStateIdValid,
    showError: showStateIdError,
    handleChange: handleStateIdChange,
    handleBlur: handleStateIdBlur,
    handleFocus: handleStateIdFocus,
    setShowError: setShowStateIdError,
  } = useInput((value) => isAlphaNumeric(value) && isNotEmpty(value));

  const {
    value: parValue,
    isValid: isParValueValid,
    showError: showParValueError,
    handleChange: handleParValueChange,
    handleBlur: handleParValueBlur,
    handleFocus: handleParValueFocus,
    setShowError: setShowParValueError,
  } = useInput((value) => isNumeric(value) && isNotEmpty(value));

  const {
    value: percentageOwnedByParent,
    isValid: isPercentageOwnedByParentValid,
    showError: showPercentageOwnedByParentError,
    handleChange: handlePercentageOwnedByParentChange,
    handleBlur: handlePercentageOwnedByParentBlur,
    handleFocus: handlePercentageOwnedByParentFocus,
    setShowError: setShowPercentageOwnedByParentError,
  } = useInput(
    (value) =>
      isNumeric(value) &&
      isNotEmpty(value) &&
      isLessThanOrEqualToValue(value, 100)
  );

  const {
    value: authorizedShares,
    isValid: isAuthorizedSharesValid,
    showError: showAuthorizedSharesError,
    handleChange: handleAuthorizedSharesChange,
    handleBlur: handleAuthorizedSharesBlur,
    handleFocus: handleAuthorizedSharesFocus,
    setShowError: setShowAuthorizedSharesError,
  } = useInput((value) => isNumeric(value) && isNotEmpty(value));

  const showAuthorizedShares = useMemo(
    () => !isNonUSCountry(selectedCountry) && entityType === "C-Corp",
    [selectedCountry, entityType]
  );

  const validateForm = () => {
    if (
      !isStateIdValid ||
      (showAuthorizedShares &&
        (!isParValueValid || !isAuthorizedSharesValid)) ||
      (shouldUploadIncorporationCertificate && !incorporationCertificate) ||
      (isSubsidiary && (!parent || !isPercentageOwnedByParentValid))
    ) {
      setShowStateIdError(!isStateIdValid);
      setShowParValueError(!isParValueValid);
      setShowAuthorizedSharesError(!isAuthorizedSharesValid);
      setShowIncorporationCertificateError(!incorporationCertificate);
      setShowParentError(!parent);
      setShowPercentageOwnedByParentError(!isPercentageOwnedByParentValid);
      return false;
    } else if (showAddress && !businessAddress.isValid) {
      setShowBusinessAddressError(!businessAddress.isValid);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    if (showAddress) {
      let files = [];
      if (shouldUploadIncorporationCertificate) {
        files = [
          "incorporation_certificate_document",
          incorporationCertificate,
        ];
      }

      const requestData = {
        stateId,
        businessAddressStreet: businessAddress.addressParams.street,
        businessAddressCity: businessAddress.addressParams.city,
        businessAddressState: businessAddress.addressParams.state,
        businessAddressCountry: businessAddress.addressParams.country,
        businessAddressZip: businessAddress.addressParams.zipCode,
        ...(showAuthorizedShares
          ? {
              authorizedShares,
              parValue,
            }
          : {}),
        ...(isSubsidiary
          ? {
              isSubsidiary,
              parent: parentEntities[parent],
              subsidizationDate: convertToISODate(subsidizationDate),
              parentPercentage: Number(percentageOwnedByParent),
            }
          : {}),
      };
      onSubmitData(requestData, files, "registration-entity");
    } else {
      setShowAddress(true);
      props.changeFormTitle(LBL__BUSINESS_ADDRESS);
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  const handleChangeIncorporationCertificate = (file) => {
    setIncorporationCertificateInfo(file);
    setShowIncorporationCertificateError(!file);
  };

  const handleSecondaryClick = () => {
    setShowAddress(false);
    props.changeFormTitle(TITLE__ENTITY_INFORMATION);
  };

  let formContent = (
    <EditFormInputs>
      <EditInputItem
        name="stateId"
        labelTitle={`${LBL__STATE_ID} / Reg No.`}
        onChange={handleStateIdChange}
        onBlur={handleStateIdBlur}
        onFocus={handleStateIdFocus}
        value={stateId}
        showError={showStateIdError}
        errorMessage={ERROR_MSG__ONLY_NUMBERS}
      />
      {shouldUploadIncorporationCertificate && (
        <EditInputItem
          name="incorporationCertificate"
          type="file"
          labelTitle={LBL__INCORPORATION_CERTIFICATE}
          onChange={handleChangeIncorporationCertificate}
          // value={incorporationCertificate}
          showError={showIncorporationCertificateError}
          errorMessage={ERROR_MSG__INCORPORATION_CERTIFICATE}
        />
      )}

      {showAuthorizedShares && (
        <>
          <EditInputItem
            name="authorizedShares"
            labelTitle={LBL__AUTHORIZED_SHARES}
            onChange={handleAuthorizedSharesChange}
            onBlur={handleAuthorizedSharesBlur}
            onFocus={handleAuthorizedSharesFocus}
            value={authorizedShares}
            showError={showAuthorizedSharesError}
            errorMessage={ERROR_MSG__NUMERICAL_VALUES}
          />

          <EditInputItem
            name="parValue"
            labelTitle={LBL__PAR_VALUE}
            onChange={handleParValueChange}
            onBlur={handleParValueBlur}
            onFocus={handleParValueFocus}
            value={parValue}
            showCurrency={true}
            showError={showParValueError}
            errorMessage={ERROR_MSG__NUMERICAL_VALUES}
          />
        </>
      )}
      {!shouldUploadIncorporationCertificate && <div />}

      <InputCheckBox
        title={LBL__IS_SUBSIDIARY}
        onChange={(event) => setIsSubsidiary(event.target.checked)}
        className={styles.checkbox}
        labelStyle={styles.label_checkbox}
        checked={isSubsidiary}
      />
      <div />

      {isSubsidiary && (
        <>
          <EditInputItem
            name="parent"
            labelTitle={LBL__PARENT}
            type="dropdown"
            onChange={(value) => {
              setParent(value);
              setShowParentError(false);
            }}
            options={parentEntities ? Object.keys(parentEntities) : []}
            value={parent}
            showListOnTop={true}
            showError={
              Object.keys(parentEntities).length == 0 || showParentError
            }
            errorMessage={
              Object.keys(parentEntities).length == 0
                ? ERROR_MSG__ADD_PARENT
                : ERROR_MSG__PARENT
            }
          />
          <EditInputItem
            name="subsidizationDate"
            type="datepicker"
            labelTitle={LBL__SUBSIDIZATION_DATE}
            onChange={setSubsidizationDate}
            value={subsidizationDate}
            startDate={new Date(incorporationDate)}
          />
          <EditInputItem
            name="percentageOwnedByParent"
            labelTitle={`${LBL__PARENT_SHARES_PERCENTAGE} owned by parent`}
            onChange={handlePercentageOwnedByParentChange}
            onBlur={handlePercentageOwnedByParentBlur}
            onFocus={handlePercentageOwnedByParentFocus}
            value={percentageOwnedByParent}
            showError={showPercentageOwnedByParentError}
            errorMessage={ERROR_MSG__LESS_THAN_100}
          />
        </>
      )}
    </EditFormInputs>
  );

  const actionContent = (
    <EditFormActionGroup
      primaryTitle={showAddress ? BTN__SUBMIT : BTN__NEXT}
      secondaryTitle={showAddress ? BTN__BACK : undefined}
      onClick={handleSubmit}
      onClickSecondary={showAddress ? handleSecondaryClick : undefined}
      isLoading={isLoading}
      disabled={isLoading}

      // disabled={
      //   !isStateIdValid ||
      //   !isParValueValid ||
      //   !isAuthorizedSharesValid ||
      //   (shouldUploadIncorporationCertificate && !incorporationCertificate) ||
      //   (isSubsidiary && !subsidizationDate) ||
      //   (isSubsidiary && !parent) ||
      //   (showAddress && !businessAddress.isValid)
      // }
    />
  );

  if (showAddress) {
    formContent = (
      <EditFormInputs key="add-new-share-holder-address">
        <EditAddressInputs
          onChange={(value) => {
            setBusinessAddress(value);
            if (value.isValid) {
              setShowBusinessAddressError(false);
            }
          }}
          defaultValue={businessAddress.addressParams}
          name="shareholderAddress"
          showGroupTitle={false}
          showError={showBusinessAddressError}
        />
      </EditFormInputs>
    );
  }

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      {formContent}
      {actionContent}
    </>
  );
};

export default EditRegistrationDetails;
