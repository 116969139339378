import { useState } from "react";
import styles from "./EditTaxClassification.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import {
  LIST__TAX_CLASSIFICATIONS,
  LBL__CURRENT_TAX_FORM,
  LIST__IRS_TAX_CLASSIFICATIONS,
} from "../../../../../../consts/TextConstants";
import { ERROR_MSG__CURRENT_TAX_FORM } from "../../../../../../consts/ErrorMessages";
import Button from "../../../../../UI/Button/Button";
import {
  useUpdateComplianceEntityMutation,
  useLazyGetUpdatedEntityQuery,
} from "../../../apis/complianceApi-slice";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";

const EditTaxClassification = ({ entityType, entityId, onClose, defaultTaxClassification }) => {
  const [error, setError] = useState({ isError: false });

  const [currentTaxForm, setCurrentTaxForm] = useState(defaultTaxClassification || "C-Corp(1120)");
  const [showCurrentTaxFormError, setShowCurrentTaxFormError] = useState(false);

  const [updateComplianceEntity, { isLoading }] =
    useUpdateComplianceEntityMutation();
  const [getComplianceEntity, { isLoading: isComplianceEntityLoading }] =
  useLazyGetUpdatedEntityQuery();

  const handleSubmit = async () => {
    try {
      const payload = {
        entity_id: entityId,
        irs_tax_classification: currentTaxForm,
      };
      const response = await updateComplianceEntity(payload).unwrap();
      await getComplianceEntity({ entity_id: entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <div className={styles.container}>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
      <div className={styles.form_inputs}>
        <EditInputItem
          name="currentTaxForm"
          type="dropdown"
          labelTitle={LBL__CURRENT_TAX_FORM}
          onChange={(value) => {
            setCurrentTaxForm(value);
            setShowCurrentTaxFormError(false);
          }}
          value={currentTaxForm}
          options={LIST__IRS_TAX_CLASSIFICATIONS}
          showError={showCurrentTaxFormError}
          errorMessage={ERROR_MSG__CURRENT_TAX_FORM}
          showListOnTop={false}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={handleSubmit}
        buttonstyle="default-blue"
        isLoading={isLoading}
      >
        Submit
      </Button>
    </div>
  );
};

export default EditTaxClassification;
