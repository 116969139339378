import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./BillingSection.module.css";
import Button from "../../../UI/Button/Button";
import promoIcon from "/assets/compliance/Promo.svg";
import promoRemoveIcon from "/assets/Close.svg";
import InfoBox from "../ui-compliance/InfoBox";
import InputCheckBox from "../../../UI/Input/InputCheckBox";
import {
  useSetCouponCodeMutation,
  useGetAddOnsListQuery,
  useCreateCompliancePaymentMutation,
} from "../apis/complianceApi-slice";
import {
  URL__STRIPE_SUCCESS_REDIRECT,
  URL__STRIPE_FAILURE_REDIRECT,
} from "../../../../consts/APIConstants";
import {
  setStripeRedirectUrls,
  getStripeRedirectUrls,
} from "../../../../utils/authentication";
import { complianceActions } from "../reducers/compliance-slice";
import MoreInfo from "../../../UI/General/MoreInfo";
import MessageDialog from "../../../UI/Modal/MessageDialog";

const BillingSection = () => {
  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [error, setError] = useState({ isError: false });


  const {
    selectedPlan,
    addOnCompliances,
    selectedEntity,
    taxPeriod,
    totalPrice,
    discountedPrice,
    priceList,
    isAccountingPlan,
    isAuditPlan,
    isComplianceDetailsConfirmed,
    addOnServices,
    foreignShareHolders,
    foreignSubsidiaries,
  } = useSelector((state) => state.compliance);

  const { compliancePeriod, complianceYear, complianceEntity } = useSelector(
    (state) => state.complianceFilters
  );

  const [setCouponCode, { isLoading }] = useSetCouponCodeMutation();

  const { isLoading: isLoadingAddOnsList } = useGetAddOnsListQuery({
    entity_type: selectedEntity?.entityType,
  });
  const [createCompliancePayment, { isLoading: isLoadingPayment }] =
    useCreateCompliancePaymentMutation();

  useEffect(() => {
    if (promoApplied) {
      dispatch(complianceActions.calculateTotalPrice());
      setPromoApplied(false);
    }
  }, [discountedPrice]);

  const handlePromoApply = async () => {
    try {
      const payload = {
        coupon_code: promoCode,
        price: discountedPrice,
      };
      const response = await setCouponCode(payload).unwrap();
      dispatch(
        complianceActions.setDiscountedPrice(
          Math.round(response.discountedPrice)
        )
      );
      // setDiscountedPrice(response.discountedPrice);
      setPromoApplied(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePromoChange = (e) => {
    setPromoCode(e.target.value);
    if (promoApplied && e.target.value != promoCode) {
      setPromoApplied(false);
    }
  };

  const handlePayment = async () => {
    try {
      let addOns = [];
      addOnCompliances.forEach((addOn) => {
        if (addOn.count > 0) {
          addOns.push({
            addon_id: addOn.id,
            addon_quantity: addOn.count,
            compliance_form: addOn.complianceForm,
            ...(addOn.complianceForm === "ST"
              ? {
                  state_initial: addOn.title,
                }
              : {}),
          });
        }
      });
      const bookKeepingServiceId = addOnServices.find(
        (service) => service.serviceName?.toLowerCase() === "one time book keeping"
      )?.serviceId;
      const auditServiceId = addOnServices.find(
        (service) => service.serviceName?.toLowerCase() === "financial audit"
      )?.serviceId;

      let foreign_shareholders = [];
      foreignShareHolders?.forEach((share) => {
        if (share?.is5472Applicable) {
          foreign_shareholders.push(share.id);
        }
      });

      let foreign_subsidiaries = [];
      foreignSubsidiaries?.forEach((subsidiary) => {
        if (subsidiary?.is5471Applicable) {
          foreign_subsidiaries.push(subsidiary.id);
        }
      });

      const payload = {
        entity_id: selectedEntity?.entityId,
        subscription_price: discountedPrice,
        compliance_filing_period: `${taxPeriod?.period.from}-${taxPeriod?.period.to}`,
        compliance_subscription_tax_year: taxPeriod?.year,
        compliance_subscription_promo_code: promoApplied ? promoCode : "",
        compliance_subscription_package_id: selectedPlan?.planId,
        // isBookeping: isAccountingPlan,
        // isAudit: isAuditPlan,
        compliance_subscription_addons: addOns,
        compliance_subscription_services: [
          ...(isAccountingPlan ? [bookKeepingServiceId] : []),
          ...(isAuditPlan ? [auditServiceId] : []),
        ],
        foreign_shareholders,
        foreign_subsidiaries,
        success_url: URL__STRIPE_SUCCESS_REDIRECT,
        cancel_url: URL__STRIPE_FAILURE_REDIRECT,
      };

      setStripeRedirectUrls("/home/compliance", "/home/compliance", {
        compliancePeriod: `${taxPeriod?.period.from}-${taxPeriod?.period.to}`,
        complianceYear,
        complianceEntity,
      });

      const response = await createCompliancePayment(payload).unwrap();
      const reditectUrl = response?.url;
      window.location.href = reditectUrl;
    } catch (err) {
      setError({
        isError: true,
        title: "Subscription error",
        message: err?.data?.message,
      });
    }
  };

  const handleAccountingPlan = () => {
    dispatch(complianceActions.setIsAccountingPlan(!isAccountingPlan));
  };

  const handleAuditPlan = () => {
    dispatch(complianceActions.setIsAuditPlan(!isAuditPlan));
  };

  const handlePromoRemove = () => {
    setPromoApplied(false);
    setPromoCode("");
    dispatch(complianceActions.calculateTotalPrice());
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <div className={styles.container}>
      <section className={styles.section_total}>
        <div className={styles.total}>
          <p>TOTAL</p>
          <p className={styles.total_subtext}>Excluding Govt. charges</p>
        </div>
        <div className={styles.price}>
          <p className={styles.original_price}>${totalPrice}</p>
          <p className={styles.discounted_price}>${discountedPrice}</p>
        </div>
      </section>
      <section className={styles.section_billing_features}>
        <div className={styles.compliance_plan}>
          <div className={styles.compliance_plan_header}>
            <p className={styles.plan_title}>{selectedPlan?.planTitle}</p>
            <p className={styles.plan_name}>{selectedPlan?.planName}</p>
          </div>
          <div className={styles.compliance_plan_features}>
            {selectedPlan?.planFeautes?.map((feature) =>
              feature.count > 0 ? (
                <InfoBox
                  key={`compliance-plan-${feature.title}`}
                  className={styles.feature}
                >
                  <p>{feature.title}</p>
                  {feature.count > 1 && (
                    <p className={styles.feature_count}>{feature.count}</p>
                  )}
                </InfoBox>
              ) : null
            )}
          </div>
          <div className={styles.feature_price}>
            <p className={styles.feature_price_original}>
              {`$${selectedPlan?.planPrice}`}
            </p>
            <p className={styles.feature_price_discounted}>
              {`$${selectedPlan?.planDiscountedPrice}`}
            </p>
          </div>
        </div>
        {addOnCompliances.length > 0 &&
          priceList?.addOns?.discountedPrice > 0 && (
            <div className={`${styles.add_ons} ${styles.add_on_compliances}`}>
              <div className={styles.add_ons_header}>
                <p>Add on Compliances</p>
              </div>
              <div className={styles.add_ons_list}>
                {addOnCompliances?.map((addOn, index) =>
                  addOn.count > 0 ? (
                    <MoreInfo
                      content={`$${addOn.compliancePrice}`}
                      position="top"
                      showMoreInfoIcon={false}
                      customContainerComponent={true}
                      key={`add-on-price-${addOn.title}-${addOn.compliancePrice}-${index}`}
                    >
                      <InfoBox
                        key={`add-on-${index}`}
                        className={styles.feature}
                      >
                        <p>{addOn.title}</p>
                        {addOn.count > 1 && (
                          <p className={styles.feature_count}>{addOn.count}</p>
                        )}
                      </InfoBox>
                    </MoreInfo>
                  ) : null
                )}
              </div>
              <div className={styles.feature_price}>
                <p className={styles.feature_price_discounted}>
                  {`$${priceList?.addOns?.discountedPrice}`}
                </p>
              </div>
            </div>
          )}

        <div className={`${styles.add_ons} ${styles.accounting_plan}`}>
          <div className={styles.add_ons_header}>
            <InputCheckBox
              title="Add catch-up accounting"
              id="accounting_plan"
              labelStyle={styles.add_ons_header}
              onChange={handleAccountingPlan}
              className={`${isAccountingPlan ? styles.checkbox : ""}`}
              checked={isAccountingPlan}
            />
            <p className={styles.accounting_plan_subtext}>
              Up-to 150 transactions
            </p>
          </div>
          <div className={styles.feature_price}>
            <p
              className={styles.feature_price_discounted}
            >{`$${priceList?.accounting?.discountedPrice}`}</p>
          </div>
        </div>

        <div className={`${styles.add_ons} ${styles.audit_plan}`}>
          <div className={styles.add_ons_header}>
            <InputCheckBox
              title="Add Audit Report"
              id="audit_plan"
              labelStyle={styles.add_ons_header}
              onChange={handleAuditPlan}
              className={`${isAuditPlan ? styles.checkbox : ""}`}
              checked={isAuditPlan}
            />
          </div>
          <div className={styles.feature_price}>
            {priceList?.audit?.actualPrice !==
              priceList?.audit?.discountedPrice && (
              <p
                className={styles.feature_price_original}
              >{`$${priceList?.audit?.actualPrice}`}</p>
            )}
            <p
              className={styles.feature_price_discounted}
            >{`$${priceList?.audit?.discountedPrice}`}</p>
          </div>
        </div>
      </section>
      <section className={styles.section_payment}>
        <div className={styles.separator}></div>
        <div className={styles.payment__box}>
          <div className={styles.promo}>
            <div className={styles.input__wrapper}>
              <input
                type="text"
                placeholder="Promo Code"
                className={styles.input__promo}
                value={promoCode}
                onChange={handlePromoChange}
                disabled={!isComplianceDetailsConfirmed || promoApplied}
              />
              <Button
                className={`${styles.btn__remove} ${
                  promoApplied ? styles.applied : ""
                }`}
                disabled={!promoApplied}
                onClick={handlePromoRemove}
              >
                <img
                  src={promoApplied ? promoRemoveIcon : promoIcon}
                  alt=""
                  className={styles.promo__icon}
                />
              </Button>
            </div>

            <Button
              className={`${styles.btn__apply} ${
                promoApplied ? styles.applied : ""
              }`}
              disabled={
                promoApplied ||
                promoCode?.length === 0 ||
                !isComplianceDetailsConfirmed
              }
              onClick={handlePromoApply}
            >
              {promoApplied ? "Applied" : "Apply"}
            </Button>
          </div>
          <Button
            className={styles.btn__pay}
            buttonstyle="green-filled"
            onClick={handlePayment}
            disabled={!isComplianceDetailsConfirmed}
            isLoading={isLoadingPayment}
          >
            Pay
          </Button>
        </div>
      </section>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
    </div>
  );
};

export default BillingSection;
