import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetEntitiesQuery } from '../Entities/apis/entityApi-slice';
import styles from './DocumentsPage.module.css';
import ErrorComponent from '../../UI/Error/ErrorComponent';
import LoadingList from '../../UI/LoadingIndicator/LoadingList';
import DocumentsEntityList from './DocumentsEntityList';
import DocumentsList from './DocumentsList';
import { ERROR_MSG__DOCUMENTS } from '../../../consts/ErrorMessages';
import Wrapper from '../../UI/Wrapper/Wrapper';

const DocumentsPage = () => {

    // State to track the selected entity
    const [selectedListItem, setSelectedListItem] = useState(null);

    // Fetching entities from Redux store
    const { entities } = useSelector((state) => state.entity);

    const { isSuccess, error, isFetching: isLoading } = useGetEntitiesQuery();

    // handleSelectListItem: Function to handle the selection of an entity from the list
    const handleSelectListItem = (status, entity) => {

        // If the entity is selected, set it as selectedListItem, otherwise null
        setSelectedListItem(status ? entity : null);
    };

    // Update selected entity when entities are fetched
    useEffect(() => {
        // If the fetch was successful and no entity is selected yet, select the first entity from the list
        if (entities.length > 0 && !selectedListItem) {
            setSelectedListItem(entities[0]);
        }
    }, [entities, selectedListItem]); //Re-run when `entities`,`selectedListItem` changes

    let content;
    if (isLoading) {
        content = <LoadingList count={4} className={styles.loading} />;
    } else if (error) {
        content = <ErrorComponent errorMessage={ERROR_MSG__DOCUMENTS} />;
    } else if (isSuccess && entities.length > 0) {
        content = (
            <>
                <DocumentsEntityList
                    entities={entities}
                    selectedListItem={selectedListItem}
                    onSelect={handleSelectListItem}
                />

                {selectedListItem && (
                    <DocumentsList
                        entityName={selectedListItem?.entityName}
                        entityId={selectedListItem?.entityId}
                    />
                )}
            </>
        );
    } else {
        content = <div>No entities available</div>;
    }

    return (
        <Wrapper title="Documents">
            <div className={styles.container}>
                {content}
            </div>
        </Wrapper>
    );
};

export default DocumentsPage;



