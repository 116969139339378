import {
  METHOD_GET,
  METHOD_POST,
  URL_GET_PRIMARY_ENTITY,
  URL__UPDATE_ENTITY,
  URL__CREATE_OTHER_ENTITY,
  URL_GET_ENTITY_DOCUMENTS,
  URL_CREATE_FOREIGN_STATE,
  URL_GET_FOREIGN_STATE,
  URL_GET_BOARD_MEMBERS,
  URL_ADD_BOARD_MEMBER,
  URL_DELETE_BOARD_MEMBER,
  URL_GET_ENTITIES,
  URL__GET_ENTITY_BY_ID,
  URL__GET_PARENT_ENTITIES
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { capTableActions } from "../../CapTable/reducers/capTable-slice";
import { entityActions } from "../reducers/entity-slice";

const entityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateEntity: builder.mutation({
      query: (args) => ({
        url: URL__UPDATE_ENTITY,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "multipart/form-data" },
        params: args.params,
        body: args.data,
      }),
    }),

    createOtherEntity: builder.mutation({
      query: (data) => ({
        url: URL__CREATE_OTHER_ENTITY,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "multipart/form-data" },
        body: data,
      }),
    }),

    getPrimaryEntity: builder.query({
      query: () => {
        return {
          url: URL_GET_PRIMARY_ENTITY,
          method: METHOD_GET,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(entityActions.handlePrimaryEntityData(data));
        } catch (error) {}
      },
    }),

    downloadFile: builder.mutation({
      query: (args) => {
        return {
          url: URL_GET_ENTITY_DOCUMENTS,
          method: METHOD_GET,
          header: { Accept: "*/*", "content-type": "multipart/form-data" },
          params: args,
          cache: "no-cache",
        };
      },
    }),

    getForeignStates: builder.query({
      query: (args) => {
        return {
          url: URL_GET_FOREIGN_STATE,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(entityActions.setForeignStates(data));
        } catch (error) {
          if (error?.error?.status == 404) {
            dispatch(entityActions.setForeignStates([]));
          }
        }
      },
    }),

    createForeignState: builder.mutation({
      query: (data) => ({
        url: URL_CREATE_FOREIGN_STATE,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "multipart/form-data" },
        body: data,
      }),
    }),

    getBoardMembers: builder.query({
      query: (args) => {
        return {
          url: URL_GET_BOARD_MEMBERS,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(entityActions.handleBoardMembers(data));
        } catch (err) {
          if (err?.error?.status == 404) {
            dispatch(
              entityActions.handleBoardMembers({
                directors: [],
                officers: [],
              })
            );
          }
        }
      },
    }),

    createBoardMember: builder.mutation({
      query: (data) => ({
        url: URL_ADD_BOARD_MEMBER,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: data,
      }),
    }),

    deleteBoardMember: builder.mutation({
      query: (data) => ({
        url: URL_DELETE_BOARD_MEMBER,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: data,
      }),
    }),

    getEntities: builder.query({
      query: () => {
        return {
          url: URL_GET_ENTITIES,
          method: METHOD_GET,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(entityActions.handleEntityListData(data));
        } catch (error) {}
      },
    }),

    getEntityById: builder.query({
      query: (args) => {
        return {
          url: URL__GET_ENTITY_BY_ID,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(entityActions.setSelectedEntity(data));
          dispatch(
            capTableActions.setAuthorizedShares(data?.entity?.authorized_shares)
          );
          dispatch(
            capTableActions.setIssuedShares(data?.entity?.issued_shares)
          );
        } catch (error) {}
      },
    }),
    getParentEntities: builder.query({
      query: (args) => {
        return {
          url: URL__GET_PARENT_ENTITIES,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(entityActions.setParentEntities(data));
        } catch (error) {}
      },
    }),

    downloadEntityDocument: builder.mutation({
      query: (args) => ({
        url: URL_GET_ENTITY_DOCUMENTS,
        method: METHOD_GET,
        params: args,
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = args.file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        },
      }),
    }),
  }),
});

export const {
  useGetPrimaryEntityQuery,
  useLazyGetPrimaryEntityQuery,
  useGetEntitiesQuery,
  useLazyGetEntitiesQuery,
  useUpdateEntityMutation,
  useCreateOtherEntityMutation,
  useDownloadFileMutation,
  useCreateForeignStateMutation,
  useGetForeignStatesQuery,
  useLazyGetForeignStatesQuery,
  useGetBoardMembersQuery,
  useCreateBoardMemberMutation,
  useDeleteBoardMemberMutation,
  useLazyGetBoardMembersQuery,
  useGetEntityByIdQuery,
  useLazyGetEntityByIdQuery,
  useGetParentEntitiesQuery,
  useDownloadEntityDocumentMutation,
} = entityApiSlice;
