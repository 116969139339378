import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CustomTable,
  TableData,
  TableHeader,
  TableRow,
} from "../../UI/Table/CustomTable";
import styles from "./AutomatedComplianceList.module.css";
import { TBL__COMPLIANCE_LIST } from "../../../consts/TextConstants";

import InfoBox from "./ui-compliance/InfoBox";
import { useLazyGetComplianceListQuery } from "./apis/complianceApi-slice";

const AutomatedCompliance = ({ dueDate, compliance, forms = [] }) => {
  return (
    <div className={styles.automated_compliance}>
      <div className={styles.left_items}>
        <div className={styles.name}>{compliance}</div>
        <div className={styles.forms}>
          {forms.map((form) => (
            <InfoBox key={`${compliance}-${form}`}>{form}</InfoBox>
          ))}
        </div>
      </div>
      <div className={styles.right_items}>{dueDate}</div>
    </div>
  );
};

const AutomatedComplianceList = () => {
  const { complianceList } = useSelector((state) => state.compliance);
  const { complianceYear, compliancePeriod, complianceEntity } = useSelector(
    (state) => state.complianceFilters
  );
  const [
    getComplianceList,
    {
      isFetching: isComplianceListLoading,
      isError: isComplianceListError,
      isSuccess: isComplianceListSuccess,
    },
  ] = useLazyGetComplianceListQuery();

  useEffect(() => {
    const fetchComplianceList = async () => {
      if (!complianceYear || !compliancePeriod || !complianceEntity) return;
      try {
        await getComplianceList({
          current_tax_year: complianceYear,
          filing_period: compliancePeriod.replaceAll(" ", ""),
          // filing_period: "Jan-Dec",
          entity_id: complianceEntity?.entityId,
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    };
    fetchComplianceList();
  }, [complianceYear, compliancePeriod, complianceEntity]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Automated compliances based on information updated on platform
      </div>
      <div className={styles.list}>
        {complianceList.map((compliance, index) => (
          <AutomatedCompliance
            key={`${compliance.compliance}-${compliance.forms}-${index}`}
            {...compliance}
          />
        ))}
      </div>
    </div>
  );
};

export default AutomatedComplianceList;
