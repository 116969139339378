import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CompliancePage from "../components/Main/Compliance/CompliancePage";

const Compliance = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the location state by replacing the current history entry
    if (location.state) {
      navigate(location.pathname, { replace: true });
    }
  }, []);

  return <CompliancePage loadFromState={location.state} />;
};

export default Compliance;
