import { apiSlice } from "../../../../store/api-slice";
import { documentsActions } from "../reducers/document-slice";
import { METHOD_GET, URL_GET_DOCUMENTS, URL_DOWNLOAD_DOCUMENTS } from "../../../../consts/APIConstants";

const documentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Fetch documents
        getDocuments: builder.query({
            query: () => ({
                url: `${URL_GET_DOCUMENTS}`,
                method: METHOD_GET,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }),

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Dispatch the entire data to setDocuments
                    dispatch(documentsActions.setDocuments(data));
                } catch (error) {
                    console.error("Error fetching documents:", error);
                }
            },
        }),

        // Fetch a specific document
        fetchDocument: builder.mutation({
            query: (args) => ({
                url: URL_DOWNLOAD_DOCUMENTS,
                method: METHOD_GET,
                params: args,
                responseHandler: (response) => response.blob(),
            }),
        }),
    }),
});

export const { useGetDocumentsQuery, useFetchDocumentMutation } = documentsApiSlice;
export default documentsApiSlice;

