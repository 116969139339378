export const handleDownloadedFile = async (
  blob,
  filename,
  shouldDownloadPdf = false
) => {
  try {
    let fileUrl;

    if (filename.split(".")[1] === "pdf" && !shouldDownloadPdf) {
      // Create a PDF blob
      const pdfBlob = new Blob([blob], { type: "application/pdf" });

      fileUrl = window.URL.createObjectURL(pdfBlob);

      // Create a new window
      const newWindow = window.open("", "_blank");

      if (!newWindow) {
        throw new Error(
          "Failed to open the new tab. Please check your browser settings."
        );
      }

      // Set the title of the new window
      newWindow.document.title = filename || "PDF Document";

      // Create an iframe to display the PDF
      newWindow.document.body.innerHTML = `<iframe width="100%" height="100%" src="${fileUrl}" frameborder="0"></iframe>`;
    } else {
      // Create a blob URL
      fileUrl = window.URL.createObjectURL(blob);

      const tempLink = document.createElement("a");
      tempLink.href = fileUrl;
      tempLink.target = "_blank";
      tempLink.setAttribute("download", filename);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
    }

    // Clean up: revoke the object URL after a short delay
    setTimeout(() => {
      window.URL.revokeObjectURL(fileUrl);
    }, 1000);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const downloadFile = async (
  url,
  queryparams,
  filename,
  shouldDownloadPdf = false
) => {
  try {
    // Fetch the PDF file
    const response = await fetch(url + queryparams, {
      credentials: "include",
      headers: {
        Accept: "*/*",
      },
    });

    // Check if the response status is OK
    if (!response.ok) {
      throw new Error("Something went wrong. Please try again.");
    }

    // Get the blob from the response
    const blob = await response.blob();
    handleDownloadedFile(blob, filename, shouldDownloadPdf);
  } catch (err) {
    console.error(err);
    throw err;
  }
};
