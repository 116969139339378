import { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { getSession } from "../lib/auth";

const SessionProvider = () => {
  const [isValidSession, setIsValidSession] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      const session = await getSession();
      if (session) {
        if (isValidSession == null || isValidSession == false) {
          setIsValidSession(true);
        }
      } else {
        if (isValidSession || isValidSession == null) {
          setIsValidSession(false);
        }
      }
    };
    checkSession();
  }, [location]);
  return <Outlet context={{ session: { isValidSession } }} />;
};

export default SessionProvider;
