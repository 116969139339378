import { FlagEmoji } from "react-international-phone";
import { TBL__CAP_TABLE_SHARES } from "../../../consts/TextConstants";
import { convertNumbersToShorts } from "../../../utils/common-utils";
import {
  CustomTable,
  TableData,
  TableFooter,
  TableHeader,
  TableRow,
} from "../../UI/Table/CustomTable";
import Button from "../../UI/Button/Button";
import styles from "./ShareHolderList.module.css";
import fileIcon from "/assets/common/Folder.svg";
import deleteIcon from "/assets/common/Delete.svg";

import fileIconHover from "/assets/common/Folder_Filled.svg";
import useHover from "../../../hooks/use-hover";

const ListItemCertificate = ({ row, onFileClick }) => {
  const [isHovered, hoverProps] = useHover();
  return (
    <div className={styles.img_data} {...hoverProps}>
      <img
        src={isHovered ? fileIconHover : fileIcon}
        alt=""
        onClick={() => onFileClick(row)}
        className={styles.img_file}
      />
    </div>
  );
};

const ShareHolderList = ({
  shareHolders,
  issuedShares,
  onFileClick,
  ...props
}) => {
  const footerData = [
    "Total",
    "",
    "",
    "",
    "",
    convertNumbersToShorts(issuedShares),
    "100 %",
  ];

  const getTableData = (key, row, shareHolder) => {
    switch (key) {
      case "certificate":
        return <ListItemCertificate row={row} onFileClick={onFileClick} />;
      case "taxResidency":
        return (
          <div className={styles.img_data_flag}>
            <FlagEmoji iso2={shareHolder[key]} className={styles.img_flag} />
          </div>
        );
      case "ownership":
        return <>{shareHolder[key] ? `${shareHolder[key]} %` : "-"}</>;
      case "parValue":
        return <>{shareHolder[key] ? `$${shareHolder[key]}` : "-"}</>;
      default:
        return <>{shareHolder[key]}</>;
    }
  };
  return (
    <CustomTable className={styles.table}>
      <thead>
        <TableRow>
          {Object.entries(TBL__CAP_TABLE_SHARES).map(([key, value], index) => (
            <TableHeader
              key={`table-header-column-${index}`}
              className={`${
                key == "certificate" || key == "taxResidency"
                  ? styles.column_fixed_width
                  : ""
              }`}
            >
              {value}
            </TableHeader>
          ))}
          <TableHeader
            key={`table-header-column-delete`}
            className={styles.column_delete}
          ></TableHeader>
        </TableRow>
      </thead>
      <tbody>
        {shareHolders.map((shareHolder, row) => (
          <TableRow key={`table-data-row-${row}`}>
            {Object.keys(TBL__CAP_TABLE_SHARES).map((key, column) => (
              <TableData key={`table-data-row-${row}-column-${column}`}>
                {getTableData(key, row, shareHolder)}
              </TableData>
            ))}
            <TableData>
              <Button onClick={() => props.onDelete([row])}>
                <img src={deleteIcon} alt="" />
              </Button>
            </TableData>
          </TableRow>
        ))}
      </tbody>
      {/* {shareHolders.length > 0 && (
        <TableFooter>
          <TableRow key={`table-footer-row-0`}>
            {footerData.map((item, index) => (
              <TableData
                key={`table-footer-row-0-column-${index}`}
                className={styles.column_footer}
              >
                {item}
              </TableData>
            ))}
          </TableRow>
        </TableFooter>
      )} */}
    </CustomTable>
  );
};

export default ShareHolderList;
