import React, { useState, useRef, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./ServiceTableRow.module.css";
import ActionItems from "./ActionItems";
import MoreInfo from "../../UI/General/MoreInfo";
import { TableData, TableRow } from "../../UI/Table/CustomTable";
import { TBL__SERVICES } from "../../../consts/TextConstants";
import rightArrow from "/assets/common/ArrowRight_White.svg";
import folderIcon from "/assets/common/Folder.svg";
import userIcon from "/assets/subscription/AssignedPerson.svg";
import Button from "../../UI/Button/Button";
import ServiceMoreInfoDropDown from "./ServiceMoreInfoDropDown";

const ServiceTableRow = ({
  serviceItem,
  row,
  column,
  handleEmail,
  handlePayClick,
  handleDocumentsClick,
  onSuccess,
}) => {
  const emailRefs = useRef(null);

  const [showMoreInfoDropdown, setShowMoreInfoDropdown] = useState(false);

  const { primaryEntity } = useSelector((state) => state.entity);

  const getPaymentStatusContent = useCallback((serviceItem) => {
    const status = serviceItem.paymentStatus?.toLowerCase() || "";
    const price = serviceItem.servicePrice
      ? ` - $${serviceItem.servicePrice}`
      : "";
    const formattedStatus = status[0]?.toUpperCase() + status?.slice(1);
    return status === "paid" ? `${formattedStatus}${price}` : formattedStatus;
  }, []);

  const renderActionItem = (serviceItem) => {
    const taskStatus = serviceItem.taskStatus?.toLowerCase();

    if (taskStatus === "active" && serviceItem.taskPhaseId) {
      return (
        <ActionItems
          serviceItem={serviceItem}
          entityId={serviceItem?.entityId}
          onSuccess={onSuccess}
        />
      );
    }
    const paymentStatus = serviceItem.paymentStatus?.toLowerCase();

    const actionConfig = {
      completed: { description: "COMPLETED", style: styles.green },
      active: { description: "IN PROGRESS", style: styles.yellow },
      pending: { description: "Complete Payment", style: styles.black },
      verification: { description: "Verifying payment", style: styles.yellow },
      quote: { description: "Under Quotation", style: styles.yellow },
    };

    const { description, style } =
      actionConfig[taskStatus] || actionConfig[paymentStatus] || {};

    return (
      <>
        {description && (
          <div className={`${styles.action_description} ${style}`}>
            <p>{description}</p>
          </div>
        )}
      </>
    );
  };

  const memoizedTableCells = useMemo(() => {
    return Object.keys(TBL__SERVICES).map((key, col) => (
      <TableData key={`service-row-${key}-${row}-${{ col }}`}>
        {key === "taskStatus" && (
          <div
            className={`${styles.task_status} ${
              styles[serviceItem.taskStatus]
            }`}
          ></div>
        )}
        {key === "serviceName" && (
          <>
            <MoreInfo
              content={`${serviceItem.serviceShortDescription} ${
                serviceItem.serviceName === "Audit & Review Report"
                  ? `: ${serviceItem?.yearDescription}`
                  : ""
              }`}
              position="top"
              showMoreInfoIcon={false}
              customContainerComponent={true}
              key={`service-details-${row}`}
            >
              <div className={styles.contentWrapper}>
                <span>{serviceItem.serviceName}</span>
              </div>
            </MoreInfo>
            {
              /* Render the ticket number */
              serviceItem.ticketNumber && (
                <div className={styles.ticket_number}>
                  {`#${serviceItem.ticketNumber}`}
                </div>
              )
            }
          </>
        )}
        {key === "description" && (
          <div className={styles.entity_name}>
            {[serviceItem?.entityName, serviceItem?.year]
              .filter(Boolean)
              .join(" . ")}
          </div>
        )}
        {key === "actionItems" && renderActionItem(serviceItem)}
        {key === "documents" &&
          (serviceItem?.documents?.length > 0 ? (
            <div
              className={styles.documents}
              onClick={() => {
                handleDocumentsClick(serviceItem);
              }}
            >
              <img src={folderIcon} alt="File" />
            </div>
          ) : (
            <div />
          ))}
        {key === "assignedTo" &&
          (serviceItem?.assigneeName ? (
            <MoreInfo
              content={serviceItem?.assigneeName}
              position="top"
              showMoreInfoIcon={false}
              customContainerComponent={true}
              key={`assignedTo-${row}`}
            >
              <div className={styles.assigned_to}>
                <img src={userIcon} alt="User" />
              </div>
            </MoreInfo>
          ) : (
            <div />
          ))}
        {key === "paymentStatus" && (
          <div className={styles.verification_status}>
            {serviceItem.paymentStatus?.toLowerCase() === "pending" ? (
              <Button
                className={styles.btn__pay}
                buttonstyle="default-blue"
                onClick={() => handlePayClick(serviceItem)}
              >
                PAY
                <img src={rightArrow} alt="" />
              </Button>
            ) : (
              <MoreInfo
                content={getPaymentStatusContent(serviceItem)}
                position="top"
                showMoreInfoIcon={false}
                customContainerComponent={true}
                key={`payment-status-${row}`}
              >
                <div
                  className={`${styles.payment_status} ${
                    styles[serviceItem.paymentStatus?.toLowerCase()]
                  }`}
                >
                  {serviceItem.paymentStatus[0]?.toUpperCase()}
                </div>
              </MoreInfo>
            )}
          </div>
        )}
        {key === "moreInfo" && (
          <ServiceMoreInfoDropDown
            key={`more-info-${row}-${column}`}
            handleEmail={handleEmail}
            serviceItem={serviceItem}
            row={row}
            column={column}
          />
        )}
      </TableData>
    ));
  }, [
    serviceItem,
    row,
    column,
    showMoreInfoDropdown,
    handleEmail,
    handlePayClick,
  ]);

  return (
    <TableRow className={styles.table_height} key={`service-row-${row}`}>
      {memoizedTableCells}
    </TableRow>
  );
};

export default ServiceTableRow;
