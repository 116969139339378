import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { complianceActions } from "../reducers/compliance-slice";
import styles from "./ConfirmDetailsSection.module.css";
import Button from "../../../UI/Button/Button";
import TabContent from "../../../UI/Tab/TabContent";
import { complianceConfirmationTabs } from "../../../../consts/Constants";
import TaxPeriodForm from "./DetailsForms/TaxPeriodForm";
import ForeignQualifications from "./DetailsForms/ForeignQualifications";
import ForeignSubsidiaries from "./DetailsForms/ForeignSubsidiaries";
import ForeignShareholders from "./DetailsForms/ForeignShareholders";
import ConfirmedDetails from "./DetailsForms/ConfirmedDetails";

const ProgressTab = ({ id, active, completed }) => {
  return (
    <div className={styles.progress_tab}>
      <div className={styles.progress_tab_title}>{id}</div>
      <div
        className={`${styles.progress_tab_status} ${
          active === id ? styles.active : completed ? styles.completed : ""
        }`}
      />
    </div>
  );
};

const ConfirmDetailsSection = () => {
  const dispatch = useDispatch();
  const { taxPeriod } = useSelector((state) => state.compliance);
  const [activeTab, setActiveTab] = useState({
    id: complianceConfirmationTabs.TAX_PERIOD,
    index: 0,
  });
  const [showError, setShowError] = useState(false);

  const validateTaxPeriod = () => {
    if (
      taxPeriod.period?.from &&
      taxPeriod.period?.to &&
      taxPeriod.taxClassification
    ) {
      setShowError(false);
      return true;
    }
    setShowError(true);
    return false;
  };
  const handleNext = () => {
    if (activeTab.id === complianceConfirmationTabs.TAX_PERIOD) {
      // dispatch(complianceActions.setComplianceTaxPeriod(null));
      if (!validateTaxPeriod()) {
        return;
      }
    }
    const tabs = Object.values(complianceConfirmationTabs);
    const nextIndex = activeTab.index + 1;

    if (activeTab.id === complianceConfirmationTabs.FOREIGN_SHAREHOLDERS) {
      dispatch(complianceActions.setIsComplianceDetailsConfirmed(true));
      setActiveTab({
        id: complianceConfirmationTabs.CONFIRMED_DATA,
        index: nextIndex,
      });
    } else {
      setActiveTab({
        id: tabs[nextIndex],
        index: nextIndex,
      });
    }
  };

  const handleBack = () => {
    const tabs = Object.values(complianceConfirmationTabs);
    if (activeTab.id === complianceConfirmationTabs.CONFIRMED_DATA) {
      dispatch(complianceActions.setIsComplianceDetailsConfirmed(false));
    }
    const prevIndex = activeTab.index - 1;
    setActiveTab({
      id: tabs[prevIndex],
      index: prevIndex,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.progress_tabs}>
        {Object.values(complianceConfirmationTabs).map(
          (tab, index) =>
            tab !== complianceConfirmationTabs.CONFIRMED_DATA && (
              <ProgressTab
                key={tab}
                id={tab}
                active={activeTab.id}
                completed={index < activeTab.index}
              />
            )
        )}
      </div>

      <div className={styles.tab_content}>
        <TabContent
          id={complianceConfirmationTabs.TAX_PERIOD}
          activeTab={activeTab.id}
        >
          <TaxPeriodForm showError={showError} />
        </TabContent>
        <TabContent
          id={complianceConfirmationTabs.FOREIGN_QUALIFICATIONS}
          activeTab={activeTab.id}
        >
          <ForeignQualifications />
        </TabContent>
        <TabContent
          id={complianceConfirmationTabs.FOREIGN_SUBSIDIARIES}
          activeTab={activeTab.id}
        >
          <ForeignSubsidiaries />
        </TabContent>
        <TabContent
          id={complianceConfirmationTabs.FOREIGN_SHAREHOLDERS}
          activeTab={activeTab.id}
        >
          <ForeignShareholders />
        </TabContent>
        <TabContent
          id={complianceConfirmationTabs.CONFIRMED_DATA}
          activeTab={activeTab.id}
        >
          <ConfirmedDetails />
        </TabContent>
      </div>

      <div className={styles.action_items}>
        <div className={styles.disclaimer}>
          {activeTab.id === complianceConfirmationTabs.TAX_PERIOD && (
            <p>*excluding ESOPs</p>
          )}
          <p>
            Inaccurate details might change the applicable compliances and cost
          </p>
        </div>
        <div className={styles.btn_container}>
          {activeTab.id !== complianceConfirmationTabs.TAX_PERIOD && (
            <Button
              buttonstyle="light-grey-outline-dark-selected"
              className={styles.btn}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {activeTab.id !== complianceConfirmationTabs.CONFIRMED_DATA && (
            <Button
              buttonstyle="default-blue"
              className={styles.btn}
              onClick={handleNext}
            >
              {activeTab.id === complianceConfirmationTabs.FOREIGN_SHAREHOLDERS
                ? "Confirm"
                : "Next"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmDetailsSection;
