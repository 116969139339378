import { useState } from "react";
import styles from "./AddForeignSharehoder.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import {
  LBL__SHAREHOLDER_NAME,
  LBL__TAX_RESIDENCY,
  LBL__ISSUE_DATE,
  LBL__NO_OF_SHARES,
  LBL__SHARE_CLASS,
  LBL__SHAREHOLDER_TYPE,
  LIST__SHAREHOLDER_TYPES,
} from "../../../../../../consts/TextConstants";
import {
  ERROR_MSG__NAME,
  ERROR_MSG__COUNTRY,
  ERROR_MSG__NO_OF_SHARES,
  ERROR_MSG__GREATER_THAN_ZERO,
  ERROR_MSG__TYPE,
} from "../../../../../../consts/ErrorMessages";
import Button from "../../../../../UI/Button/Button";
import useInput from "../../../../../../hooks/use-input";

import {
  isBetweenRange,
  isGreaterThanOrEqualToValue,
  isNotEmpty,
  isNumeric,
} from "../../../../../../utils/validations";
import { getCountriesList } from "../../../../../../utils/common-utils";
import {
  useCreateForeignShareHolderMutation,
  useLazyGetForeignShareHoldersQuery,
} from "../../../apis/complianceApi-slice";
import { convertToISODate } from "../../../../../../utils/common-utils";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";

const countryList = getCountriesList();

const AddForeignSharehoder = ({
  incorporationDate,
  entityId,
  onClose,
  complianceDate,
}) => {
  const [country, setCountry] = useState("");
  const [shareholderType, setShareholderType] = useState("");
  const [issuedDate, setIssuedDate] = useState(
    new Date(Math.min(new Date(), complianceDate?.toDate))
  );
  const [shareClass, setShareClass] = useState("Common");
  const [showShareholderTypeError, setShowShareholderTypeError] =
    useState(false);
  const [showCountryError, setShowCountryError] = useState(false);
  const [error, setError] = useState({ isError: false });

  const {
    value: shareHolderName,
    isValid: isShareHolderNameValid,
    showError: showShareHolderNameError,
    handleChange: handleShareHolderNameChange,
    handleBlur: handleShareHolderNameBlur,
    handleFocus: handleShareHolderNameFocus,
    setShowError: setShowShareHolderNameError,
  } = useInput(isNotEmpty);

  const {
    value: noOfShares,
    isValid: isNoOfSharesValid,
    showError: showNoOfSharesError,
    handleChange: handleNoOfSharesChange,
    handleBlur: handleNoOfSharesBlur,
    handleFocus: handleNoOfSharesFocus,
    setShowError: setShowNoOfSharesError,
  } = useInput(
    (value) =>
      isNumeric(value) &&
      isGreaterThanOrEqualToValue(value, 1) &&
      isNotEmpty(value)
  );

  const [createForeignShareHolder, { isLoading }] =
    useCreateForeignShareHolderMutation();

  const [getForeignShareHolders, { isLoading: isForeignShareHoldersLoading }] =
    useLazyGetForeignShareHoldersQuery();

  const validateForm = () => {
    if (!shareholderType || !isShareHolderNameValid || !isNoOfSharesValid || !country) {
      setShowShareholderTypeError(!shareholderType);
      setShowShareHolderNameError(!isShareHolderNameValid);
      setShowNoOfSharesError(!isNoOfSharesValid);
      setShowCountryError(!country);
      return false;
    }
    return true;
  };

  const handleAddForeignShareholder = async () => {
    /* TODO: add foreign subsidiary logic */
    if (!validateForm()) return;
    try {
      const payload = {
        parent_entity_id: entityId,
        shareholder_type: shareholderType,
        shareholder_name: shareHolderName,
        shareholder_issued_date: convertToISODate(issuedDate),
        shareholder_no_of_shares: Number(noOfShares),
        shareholder_tax_residency: country,
        share_type: shareClass,
      };
      const response = await createForeignShareHolder(payload).unwrap();
      await getForeignShareHolders({ entity_id: entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <div className={styles.container}>
      <div className={styles.form_inputs}>
        <EditInputItem
          name="shareholderType"
          type="dropdown"
          labelTitle={LBL__SHAREHOLDER_TYPE}
          onChange={(value) => {
            setShareholderType(value);
            setShowShareholderTypeError(false);
          }}
          value={shareholderType}
          options={LIST__SHAREHOLDER_TYPES}
          showError={showShareholderTypeError}
          errorMessage={ERROR_MSG__TYPE}
        />

        <EditInputItem
          name="shareHolderName"
          labelTitle={LBL__SHAREHOLDER_NAME}
          onChange={handleShareHolderNameChange}
          onBlur={handleShareHolderNameBlur}
          onFocus={handleShareHolderNameFocus}
          value={shareHolderName}
          showError={showShareHolderNameError}
          errorMessage={ERROR_MSG__NAME}
        />

        <EditInputItem
          name="issuedDate"
          type="datepicker"
          labelTitle={LBL__ISSUE_DATE}
          onChange={(value) => setIssuedDate(value)}
          value={issuedDate}
          startDate={new Date(incorporationDate)}
          endDate={new Date(Math.min(new Date(), complianceDate?.toDate))}
        />

        <EditInputItem
          name="noOfShares"
          labelTitle={LBL__NO_OF_SHARES}
          onChange={handleNoOfSharesChange}
          onBlur={handleNoOfSharesBlur}
          onFocus={handleNoOfSharesFocus}
          value={noOfShares}
          showError={showNoOfSharesError}
          errorMessage={
            noOfShares <= 0
              ? ERROR_MSG__GREATER_THAN_ZERO
              : ERROR_MSG__NO_OF_SHARES
          }
        />

        <EditInputItem
          name={`country`}
          type="autocomplete"
          labelTitle={LBL__TAX_RESIDENCY}
          value={country || ""}
          // defaultValue={}
          onChange={(item) => {
            setCountry(item.value);
            setShowCountryError(false);
          }}
          suggestions={countryList}
          showError={showCountryError}
          errorMessage={ERROR_MSG__COUNTRY}
        />

        <EditInputItem
          name="shareClass"
          type="switch"
          labelTitle={LBL__SHARE_CLASS}
          active={shareClass}
          onChange={setShareClass}
          leftLabel="Common"
          rightLabel="Preferred"
          className={styles.switch_input}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={handleAddForeignShareholder}
        buttonstyle="default-blue"
        isLoading={isLoading}
      >
        Submit
      </Button>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
    </div>
  );
};

export default AddForeignSharehoder;
