import { useState } from "react";
import { useSelector } from "react-redux";
import { FlagEmoji } from "react-international-phone";
import styles from "./ForeignSubsidiaries.module.css";
import AddNewDetailButton from "../../ui-compliance/AddNewDetailButton";
import MoreInfoButton from "../../ui-compliance/MoreInfoButton";
import InfoBox from "../../ui-compliance/InfoBox";
import { countriesLookup } from "../../../../../utils/common-utils";
import EditOverlay from "./EditForms/EditOverlay";
import { complianceEditForms } from "../../../../../consts/Constants";
import pieChartIcon from "/assets/compliance/Chart_Pie.svg";
import {
  useGetForeignSubsidiariesQuery,
  useLazyGetForeignSubsidiariesQuery,
} from "../../apis/complianceApi-slice";

const ForeignSubsidiary = ({ foreignSubsidiary, onDissolve }) => {
  const country = countriesLookup(foreignSubsidiary.incorporationCountry).key;

  return (
    <div className={styles.foreign__subsidiary}>
      <div className={styles.entity__name}>{foreignSubsidiary.entityName}</div>
      <InfoBox className={styles.parent_percentage}>
        <img src={pieChartIcon} alt="" />
        {foreignSubsidiary.parentPercentage}%
      </InfoBox>
      <div className={styles.entity__details_wrapper}>
        <div className={styles.entity_details}>
          <InfoBox className={styles.country}>
            <FlagEmoji iso2={country} className={styles.img_flag} />
            {foreignSubsidiary.incorporationCountry}
          </InfoBox>
          <InfoBox className={styles.entity__type}>
            {foreignSubsidiary.entityType}
          </InfoBox>
        </div>
        {foreignSubsidiary.is5471Applicable && (
          <InfoBox className={styles.entity__percentage} infostyle="light-violet">
            {foreignSubsidiary.formName}
          </InfoBox>
        )}
      </div>
      {/* <MoreInfoButton
        className={styles.btn__more_info}
        name={foreignSubsidiary.entityName}
        onSelect={onDissolve}
        options={[
          { name: "Dissolve", icon: "/assets/common/Prohibition.svg" },
          { name: "Remove", icon: "/assets/common/Minus_Gray.svg" },
        ]}
      /> */}
    </div>
  );
};

const ForeignSubsidiaries = () => {
  const { foreignSubsidiaries, selectedEntity, complianceDate } = useSelector(
    (state) => state.compliance
  );

  const [editOverlay, setEditOverlay] = useState({ show: false, formName: "" });

  // const {isLoading: isForeignSubsidiariesLoading } = useGetForeignSubsidiariesQuery({
  //   entity_id: selectedEntity?.entityId,
  // });


  const handleDissolve = (subsidiary, option) => {
    const formName =
      option === "Dissolve"
        ? complianceEditForms.DISSOLVE_ENTITY
        : complianceEditForms.UNSUBSIDIZE_ENTITY;
    setEditOverlay({
      show: true,
      formName: formName,
      foreignSubsidiary: subsidiary,
    });
  };

  const handleAddForeignSubsidiary = () => {
    setEditOverlay({
      show: true,
      formName: complianceEditForms.FOREIGN_SUBSIDIARIES,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Confirm foreign subsidiaries of the entity
      </div>

      <div className={styles.content}>
        <AddNewDetailButton
          label="Add Entity Details"
          className={styles.btn__add}
          onClick={handleAddForeignSubsidiary}
        />
        {foreignSubsidiaries.map((subsidiary) => (
          <ForeignSubsidiary
            key={subsidiary.entityName}
            foreignSubsidiary={subsidiary}
            onDissolve={(option) => handleDissolve(subsidiary, option)}
          />
        ))}
      </div>
      {editOverlay.show && (
        <EditOverlay
          formName={editOverlay.formName}
          foreignSubsidiary={editOverlay.foreignSubsidiary}
          onClose={() => setEditOverlay({ show: false, formName: "" })}
          className={
            editOverlay.formName === complianceEditForms.FOREIGN_SUBSIDIARIES
              ? styles.add_overlay
              : styles.remove_overlay
          }
          parentEntity={selectedEntity}
          complianceDate={complianceDate}
        />
      )}
    </div>
  );
};

export default ForeignSubsidiaries;
