import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    documents: {}, // Object with entityId as key and files as values
};

const documentSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {
        setDocuments(state, action) {
            // Temporary storage for incoming documents
            const tempDocuments = {};

            // Process the payload into the temporary object
            action.payload.forEach(({ entity_id, files }) => {
                tempDocuments[entity_id] = files;
            });
            state.documents = tempDocuments;
        },
    },
});

export const documentsActions = documentSlice.actions;
export default documentSlice.reducer;