import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import SignInUpLandingPage from "../components/Login/SignInUpLandingPage.jsx";
import LoadingComponent from "../components/UI/LoadingIndicator/LoadingComponent.jsx";

const Login = () => {
  const location = useLocation();
  const { session } = useOutletContext();

  if (session?.isValidSession == null) {
    return <LoadingComponent />;
  }

  return session?.isValidSession && !location.state?.showUserDetailsPage ? (
    <Navigate to="/home" state={{ from: location }} replace />
  ) : (
    <SignInUpLandingPage />
  );
};

export default Login;
