import { useState } from "react";
import styles from "./AddForeignSubsidiary.module.css";
import EditInputItem from "../../../../Edit/EditInputItem";
import {
  LBL__ENTITY_NAME,
  LBL__COUNTRY,
  LBL__ENTITY_TYPE,
  LBL__STATE,
  entityTypes,
  LBL__INCORPORATION_DATE,
  LBL__SUBSIDIZATION_DATE,
  LBL__PARENT_SHARES_PERCENTAGE,
} from "../../../../../../consts/TextConstants";
import {
  ERROR_MSG__ENTITY_NAME,
  ERROR_MSG__COUNTRY,
  ERROR_MSG__ENTITY_TYPE,
  ERROR_MSG__LESS_THAN_100,
  ERROR_MSG__STATE,
} from "../../../../../../consts/ErrorMessages";
import Button from "../../../../../UI/Button/Button";
import useInput from "../../../../../../hooks/use-input";
import {
  isLessThanOrEqualToValue,
  isNotEmpty,
  isNumeric,
} from "../../../../../../utils/validations";
import {
  getCountriesList,
  convertToISODate,
} from "../../../../../../utils/common-utils";
import {
  useCreateForeignSubsidiaryMutation,
  useLazyGetForeignSubsidiariesQuery,
} from "../../../apis/complianceApi-slice";
import MessageDialog from "../../../../../UI/Modal/MessageDialog";

const countryList = getCountriesList();

const AddForeignSubsidiary = ({ parentEntity, onClose, complianceDate }) => {
  const [country, setCountry] = useState("");
  const [entityType, setEntityType] = useState("");
  const [incorporationDate, setIncorporationDate] = useState(
    complianceDate?.fromDate
  );
  const [subsidizationDate, setSubsidizationDate] = useState(
    new Date(Math.min(new Date(), complianceDate?.toDate))
  );

  const [showEntityTypeError, setShowEntityTypeError] = useState(false);
  const [showCountryError, setShowCountryError] = useState(false);
  const [showSubsidizationDateError, setShowSubsidizationDateError] =
    useState(false);
  const [error, setError] = useState({ isError: false });

  const {
    value: entityName,
    isValid: isEntityNameValid,
    showError: showEntityNameError,
    handleChange: handleEntityNameChange,
    handleBlur: handleEntityNameBlur,
    handleFocus: handleEntityNameFocus,
    setShowError: setShowEntityNameError,
  } = useInput(isNotEmpty);

  const {
    value: parValue,
    isValid: isParValueValid,
    showError: showParValueError,
    handleChange: handleParValueChange,
    handleBlur: handleParValueBlur,
    handleFocus: handleParValueFocus,
    setShowError: setShowParValueError,
  } = useInput(
    (value) =>
      isNumeric(value) &&
      isNotEmpty(value) &&
      isLessThanOrEqualToValue(value, 100)
  );

  const {
    value: state,
    isValid: isStateValid,
    showError: showStateError,
    handleChange: handleStateChange,
    handleBlur: handleStateBlur,
    handleFocus: handleStateFocus,
    setShowError: setShowStateError,
  } = useInput(isNotEmpty);

  const [createForeignSubsidiary, { isLoading }] =
    useCreateForeignSubsidiaryMutation();

  const [getForeignSubsidiaries, { isLoading: isForeignSubsidiariesLoading }] =
    useLazyGetForeignSubsidiariesQuery();

  const validateForm = () => {
    if (
      !isEntityNameValid ||
      !country ||
      !entityType ||
      !isParValueValid ||
      !isStateValid
    ) {
      setShowEntityNameError(!isEntityNameValid);
      setShowStateError(!isStateValid);
      setShowCountryError(!country);
      setShowEntityTypeError(!entityType);
      setShowParValueError(!isParValueValid);
      return false;
    }
    return true;
  };

  const handleAddForeignSubsidiary = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const payload = {
        parentEntityId: parentEntity?.entityId,
        entityName: entityName,
        entityType: entityType,
        country: country,
        incorporation_state: state,
        incorporationDate: convertToISODate(incorporationDate),
        subsidizationDate: convertToISODate(subsidizationDate),
        parentOwnershipPercentage: parseFloat(parValue),
      };
      const response = await createForeignSubsidiary(payload).unwrap();
      await getForeignSubsidiaries({ entity_id: parentEntity?.entityId });
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <div className={styles.container}>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={handleCloseError}
        />
      )}
      <div className={styles.form_inputs}>
        <EditInputItem
          name="entityName"
          labelTitle={LBL__ENTITY_NAME}
          onChange={handleEntityNameChange}
          onBlur={handleEntityNameBlur}
          onFocus={handleEntityNameFocus}
          value={entityName}
          showError={showEntityNameError}
          errorMessage={ERROR_MSG__ENTITY_NAME}
        />

        <EditInputItem
          name={`country`}
          type="autocomplete"
          labelTitle={LBL__COUNTRY}
          value={country || ""}
          // defaultValue={}
          onChange={(item) => {
            setCountry(item.value);
            setShowCountryError(false);
          }}
          suggestions={countryList}
          showError={showCountryError}
          errorMessage={ERROR_MSG__COUNTRY}
        />

        <EditInputItem
          name="state"
          labelTitle={LBL__STATE}
          onChange={handleStateChange}
          onBlur={handleStateBlur}
          onFocus={handleStateFocus}
          value={state}
          showError={showStateError}
          errorMessage={ERROR_MSG__STATE}
        />

        <EditInputItem
          name="entityType"
          labelTitle={LBL__ENTITY_TYPE}
          type="dropdown"
          onChange={(type) => {
            setEntityType(type);
            setShowEntityTypeError(false);
          }}
          options={entityTypes.NON_US}
          value={entityType}
          showError={showEntityTypeError}
          errorMessage={ERROR_MSG__ENTITY_TYPE}
        />
        <EditInputItem
          name="incorporationDate"
          type="datepicker"
          labelTitle={LBL__INCORPORATION_DATE}
          onChange={(value) => setIncorporationDate(value)}
          value={incorporationDate}
          endDate={new Date(subsidizationDate)}
        />

        <EditInputItem
          name="subsidizationDate"
          type="datepicker"
          labelTitle={LBL__SUBSIDIZATION_DATE}
          onChange={setSubsidizationDate}
          value={subsidizationDate}
          startDate={
            new Date(
              Math.max(
                new Date(incorporationDate),
                new Date(parentEntity?.incorporationDate)
              )
            )
          }
          endDate={new Date()}
        />

        <EditInputItem
          name="parValue"
          labelTitle={`${LBL__PARENT_SHARES_PERCENTAGE} ${parentEntity?.entityName}`}
          onChange={handleParValueChange}
          onBlur={handleParValueBlur}
          onFocus={handleParValueFocus}
          value={parValue}
          showError={showParValueError}
          errorMessage={ERROR_MSG__LESS_THAN_100}
        />
      </div>
      <Button
        className={styles.btn__submit}
        onClick={handleAddForeignSubsidiary}
        buttonstyle="default-blue"
        isLoading={isLoading}
      >
        Submit
      </Button>
    </div>
  );
};

export default AddForeignSubsidiary;
