import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./PrimaryEntityDetails.module.css";
import {
  LBL__AUTHORIZED_SHARES,
  LBL__STATE_ID,
  LBL__ENTITY_TYPE,
  LBL__INCORPORATION_CERTIFICATE,
  LBL__INCORPORATION,
  LBL__INCORPORATION_DATE,
  LBL__BUSINESS_ADDRESS,
  LBL__EIN_NUMBER,
  LBL__EIN_LETTER,
  LBL__CURRENT_TAX_YEAR,
  LBL__CURRENT_TAX_FORM,
  LBL__ADDRESS,
  LBL__AGENT_NAME,
  TITLE__REGISTRATION,
  TITLE__IRS,
  TITLE__REGISTERED_AGENT,
  LBL__SUBSIDIZATION_DATE,
  LBL__PARENT,
} from "../../../../consts/TextConstants";
import { convertISODateToStr } from "../../../../utils/common-utils";
import { downloadFile } from "../../../../utils/fileHandler";
import { useState } from "react";
import EntityEditPage from "../EntityEdit/EntityEditPage";
import { entityEditForms } from "../../../../consts/Constants";
import { entityActions } from "../reducers/entity-slice";
import ForeignStatesDetails from "./ForeignStatesDetails";
import fileIcon from "/assets/common/Folder.svg";
import fileIconHover from "/assets/common/Folder_Blue.svg";
import AddDataButton from "../../Edit/AddDataButton";
import {
  BASE_URL,
  URL_GET_ENTITY_DOCUMENTS,
} from "../../../../consts/APIConstants";
import { useLazyGetEntityByIdQuery } from "../apis/entityApi-slice";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";
import { ERROR_MSG__ENTITY_DETAILS } from "../../../../consts/ErrorMessages";
import { isNonUSCountry } from "../../../../utils/validations";
import useHover from "../../../../hooks/use-hover";
import { useDownloadEntityDocumentMutation } from "../apis/entityApi-slice";

export const EntityDetail = ({ title, value, type, ...props }) => {
  const [isHovered, hoverProps] = useHover();
  return (
    <div className={styles.detail} {...props}>
      <div className={styles.detail__title}>{title}</div>
      <div
        className={`${styles.detail__value} ${
          type == "file" ? styles.detail__file : ""
        }`}
        {...hoverProps}
      >
        {type == "file" && (
          <img src={isHovered ? fileIconHover : fileIcon} alt="" />
        )}
        <div> {value}</div>
      </div>
      {isHovered && <div className={styles.file_info}>{props.filename}</div>}
    </div>
  );
};

const PrimaryEntityDetails = () => {
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const { selectedEntity } = useSelector((state) => state.entity);

  const dispatch = useDispatch();

  const {
    registrationInfo: entityInfo,
    irsInfo,
    agentInfo,
  } = selectedEntity || {};

  const [getEntityById, { isFetching: isLoading, isSuccess, isError }] =
    useLazyGetEntityByIdQuery();

  const [downloadDocument] = useDownloadEntityDocumentMutation();

  const isCountryNonUS = useMemo(() => {
    return isNonUSCountry(selectedEntity?.incorporationCountry);
  }, [selectedEntity]);

  const handleSuccess = async () => {
    try {
      await getEntityById({ entity_id: selectedEntity?.entityId }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (editForm) => {
    dispatch(entityActions.setEditForm(editForm));
    setOpenEditOverlay(true);
  };

  const handleEditClose = () => {
    setOpenEditOverlay(false);
  };

  const handleDownloadFile = async (fileName, fileType) => {
    if (!fileName) return;

    try {
      // const response = await downloadDocument({
      //   entity_id: selectedEntity?.entityId,
      //   file_name: fileName,
      //   field_name: fileType,
      // }).unwrap();

      // const blob = await response.blob();

      // await handleDownloadedFile(blob, fileName, true);
      const fileDownloadURL = BASE_URL + URL_GET_ENTITY_DOCUMENTS;

      const queryparams = `?entity_id=${encodeURIComponent(
        selectedEntity?.entityId
      )}&file_name=${encodeURIComponent(fileName)}&field_name=${fileType}`;

      await downloadFile(fileDownloadURL, queryparams, fileName);
    } catch (err) {
      console.error("Download failed:", err);
    }
  };

  if (isLoading) {
    return <LoadingComponent type="data" />;
  }

  if (isError) {
    return <ErrorComponent errorMessage={ERROR_MSG__ENTITY_DETAILS} />;
  }

  return (
    <>
      <div className={styles.container}>
        <section
          className={`${styles.registration_details} ${
            isCountryNonUS ? styles.section_full_width : ""
          }`}
        >
          {selectedEntity?.isRegistrationEditable && (
            <AddDataButton
              onClick={() => handleEditClick(entityEditForms.REGISTRATION)}
            />
          )}

          <div className={styles.section_header}>{TITLE__REGISTRATION}</div>
          <div className={styles.section_content}>
            <EntityDetail
              title={LBL__INCORPORATION}
              value={`${selectedEntity?.incorporationState} (${
                isCountryNonUS ? selectedEntity?.incorporationCountry : "Home"
              })`}
            />
            <EntityDetail
              title={`${LBL__STATE_ID} / Reg No.`}
              value={entityInfo?.stateId || "-"}
            />

            <EntityDetail
              title={LBL__INCORPORATION_CERTIFICATE}
              // value={
              //   primaryEntity?.incorporationCertificate
              //     ? getShortFileName(primaryEntity?.incorporationCertificate)
              //     : "-"
              // }
              value="Download"
              type="file"
              filename={selectedEntity?.incorporationCertificate}
              onClick={() =>
                handleDownloadFile(
                  selectedEntity?.incorporationCertificate,
                  "incorporation_certificate_document"
                )
              }
            />

            <EntityDetail
              title={LBL__ENTITY_TYPE}
              value={selectedEntity?.entityType}
            />

            <EntityDetail
              title={LBL__INCORPORATION_DATE}
              value={convertISODateToStr(selectedEntity?.incorporationDate)}
            />

            <EntityDetail
              title={LBL__BUSINESS_ADDRESS}
              value={entityInfo?.businessAddress || "-"}
            />

            {!isCountryNonUS && selectedEntity?.entityType === "C-Corp" && (
              <EntityDetail
                title={LBL__AUTHORIZED_SHARES}
                value={`${
                  entityInfo?.authorizedShares
                }@${entityInfo?.parValue.toString()}`}
              />
            )}

            {entityInfo?.isSubsidiary && (
              <>
                <EntityDetail
                  title={LBL__PARENT}
                  value={entityInfo?.parent ? `${entityInfo?.parent}` : "-"}
                />
                <EntityDetail
                  title={LBL__SUBSIDIZATION_DATE}
                  value={
                    convertISODateToStr(entityInfo?.subsidizationDate) || "-"
                  }
                />
              </>
            )}
          </div>
        </section>

        {!isCountryNonUS && (
          <>
            <section className={styles.irs_section}>
              {selectedEntity?.isIrsEditable && (
                <AddDataButton
                  buttonLabel="EIN Details"
                  onClick={() => handleEditClick(entityEditForms.IRS)}
                />
              )}
              <div className={styles.section_header}>{TITLE__IRS}</div>
              {!selectedEntity?.isIrsEditable && (
                <div
                  className={`${styles.section_content} ${styles.section_content_irs}`}
                >
                  <EntityDetail
                    title={LBL__EIN_NUMBER}
                    value={irsInfo?.einNumber || "-"}
                  />
                  <EntityDetail
                    title={LBL__EIN_LETTER}
                    // value={irsInfo?.einLetter ? getShortFileName(irsInfo?.einLetter) : "-"}
                    value="Download"
                    type="file"
                    filename={irsInfo?.einLetter}
                    onClick={() =>
                      handleDownloadFile(
                        irsInfo?.einLetter,
                        "ein_letter_document"
                      )
                    }
                  />
                  <EntityDetail
                    title={LBL__CURRENT_TAX_YEAR}
                    value={irsInfo?.currentTaxYear || "-"}
                  />
                  <EntityDetail
                    title={LBL__CURRENT_TAX_FORM}
                    value={irsInfo?.currentTaxForm || "-"}
                  />
                </div>
              )}
            </section>

            <section className={styles.registered_agent_section}>
              {selectedEntity?.isAgentEditable && (
                <AddDataButton
                  buttonLabel="Registered Agent Details"
                  onClick={() => handleEditClick(entityEditForms.AGENT)}
                />
              )}
              <div className={styles.section_header}>
                {TITLE__REGISTERED_AGENT}
              </div>
              {!selectedEntity?.isAgentEditable && (
                <div className={styles.section_content}>
                  <EntityDetail
                    title={LBL__AGENT_NAME}
                    value={agentInfo?.agentName || "-"}
                  />
                  <EntityDetail
                    title={LBL__ADDRESS}
                    value={agentInfo?.agentAddress || "-"}
                  />
                </div>
              )}
            </section>

            {/* <ForeignStatesDetails /> */}
          </>
        )}
      </div>
      {openEditOverlay && (
        <EntityEditPage
          onClose={handleEditClose}
          entityId={selectedEntity?.entityId}
          entityType={selectedEntity?.entityType}
          incorporationDate={selectedEntity?.incorporationDate}
          showEINLetter={irsInfo?.einLetter == null}
          lockedStateAddress={selectedEntity?.incorporationState}
          lockedCountryAddress={"United States"}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default PrimaryEntityDetails;
