import styles from "./AddNewDetailButton.module.css";
import Button from "../../../UI/Button/Button";
import plusIcon from "/assets/common/Plus_White.svg";

const AddNewDetailButton = ({ label, className, ...props }) => {
  return (
    <Button
      buttonstyle="light-grey-outline-dark-selected"
      className={`${styles.btn__container} ${className}`}
      {...props}
    >
      <div className={styles.btn__icon}>
        <img src={plusIcon} alt="" />
      </div>
      <div className={styles.btn__label}>{label}</div>
    </Button>
  );
};

export default AddNewDetailButton;
