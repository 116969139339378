import { FlagEmoji } from "react-international-phone";
import styles from "./SubscriptionOverlay.module.css";
import BackDrop from "../../../UI/Modal/Backdrop";
import Button from "../../../UI/Button/Button";
import closeIcon from "/assets/Close.svg";
import InfoBox from "../ui-compliance/InfoBox";
import { countriesLookup } from "../../../../utils/common-utils";
import BillingSection from "./BillingSection";
import ConfirmDetailsSection from "./ConfirmDetailsSection";
import {
  useGetCompliancePackagesQuery,
  useGetUpdatedEntityQuery,
  useGetForeignSubsidiariesQuery,
  useGetForeignShareHoldersQuery,
  useGetForeignQualificationsQuery,
  useGetAddOnsListQuery,
} from "../apis/complianceApi-slice";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import { useMemo } from "react";
import { formatPeriod } from "../../../../utils/common-utils";
import { useSelector, useDispatch } from "react-redux";
import { complianceActions } from "../reducers/compliance-slice";

const DetailField = ({ label, value }) => {
  return (
    <div className={styles.detail_field}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

const SubscriptionOverlay = ({ onClose }) => {
  const dispatch = useDispatch();

  const { selectedEntity, taxPeriod } = useSelector(
    (state) => state.compliance
  );

  const formattedPeriod = useMemo(() => {
    return formatPeriod(
      taxPeriod?.year,
      `${taxPeriod?.period?.from} - ${taxPeriod?.period?.to}`
    );
  }, [taxPeriod]);

  const countryKey = useMemo(
    () => countriesLookup(selectedEntity?.country)?.key,
    []
  );
  const { isFetching: isLoading } = useGetCompliancePackagesQuery(
    {
      entity_type: "C-Corp",
      // entity_type: selectedEntity?.entityType || "C-Corp",
    }
    // { skip: !entityType }
  );

  const { isFetching: isLoadingAddOnsList } = useGetAddOnsListQuery(
    {
      entity_type: selectedEntity?.entityType,
    },
    { refetchOnMountOrArgChange: true }
  );

  const { isFetching: isForeignSubsidiariesLoading } =
    useGetForeignSubsidiariesQuery(
      {
        entity_id: selectedEntity?.entityId,
      },
      {
        skip: isLoading || isLoadingAddOnsList,
        refetchOnMountOrArgChange: true,
      }
    );

  const { isFetching: isForeignShareHoldersLoading } =
    useGetForeignShareHoldersQuery(
      {
        entity_id: selectedEntity?.entityId,
      },
      {
        skip: isLoading || isLoadingAddOnsList,
        refetchOnMountOrArgChange: true,
      }
    );

  const { isFetching: updatedEntityLoading } = useGetUpdatedEntityQuery(
    {
      entity_id: selectedEntity?.entityId,
    },
    {
      skip:
        selectedEntity?.entityId == null ||
        selectedEntity?.entityId == undefined,
      refetchOnMountOrArgChange: true,
    }
  );

  const { isFetching: isForeignQualificationsLoading } =
    useGetForeignQualificationsQuery(
      {
        entity_id: selectedEntity?.entityId,
      },
      {
        skip: isLoading || isLoadingAddOnsList,
        refetchOnMountOrArgChange: true,
      }
    );

  if (isLoading) {
    return <LoadingComponent />;
  }
  const handleClose = () => {
    dispatch(complianceActions.resetAddOnCompliances());
    onClose();
  };
  return (
    <BackDrop className={styles.backdrop}>
      <div className={styles.overlay}>
        <Button className={styles.btn__close} onClick={handleClose}>
          <img src={closeIcon} alt="" />
        </Button>
        <div className={styles.title}>
          {`${taxPeriod?.year} Compliance Confirmation`}
        </div>
        <div className={styles.content}>
          <section
            className={`${styles.section} ${styles.section__basic_details}`}
          >
            <div className={styles.left}>
              <div className={styles.entity_name}>
                {selectedEntity?.entityName}
              </div>
              <div className={styles.entity_details}>
                <InfoBox key="entityType" className={styles.entity_detail}>
                  {taxPeriod?.entityType}
                </InfoBox>
                <InfoBox
                  key="incorporationState"
                  className={styles.entity_detail}
                >
                  <FlagEmoji iso2={countryKey} className={styles.img_flag} />
                  {selectedEntity?.state}
                </InfoBox>
              </div>
            </div>
            <div className={styles.right}>
              <DetailField
                label="Federal Form"
                value={taxPeriod?.entityType || "-"}
              />
              <DetailField label="Year" value={taxPeriod?.year || "-"} />
              <DetailField
                label="Filing Period"
                value={taxPeriod?.period?.from ? formattedPeriod || "-" : "-"}
              />
            </div>
          </section>
          <section
            className={`${styles.section} ${styles.section__compliance_details}`}
          >
            <ConfirmDetailsSection />
          </section>
          <section
            className={`${styles.section} ${styles.section__billing_details}`}
          >
            <BillingSection />
          </section>
        </div>
      </div>
    </BackDrop>
  );
};

export default SubscriptionOverlay;
