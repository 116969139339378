
import { useState, useRef } from "react";
import styles from "./FileInputDragDrop.module.css";
import UploadBlack from "/assets/common/UploadUpSide_Black.svg";
import UploadBlue from "/assets/common/UploadUpSide_Blue.svg";
import uploadedDocument from "/assets/common/Document_Blue.svg";
import useHover from "../../../hooks/use-hover";

const FileInputDragDrop = ({
    label = "Choose a file or Drag & drop it here.",
    className,
    acceptedFilesType = ".pdf, .xls, .xlsx",
    filesLimit = 1,
    onChange,
    ...props

}) => {
    const [dragOver, setDragOver] = useState(false);
    const [isHovered, hoverProps] = useHover();
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const fileInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => setDragOver(false);

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const files = Array.from(e.dataTransfer.files);
        validateAndHandleFiles(files);
    };

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        fileInputRef.current.value = "";
        if (filesLimit === 1) {
            setUploadedFileName(files[0]?.name);
        }
        onChange(files);
    };

    const validateAndHandleFiles = (files) => {
        const acceptedFileTypes = acceptedFilesType.split(",").map((type) => type.trim());
        const invalidFiles = files.filter((file) => {
            const ext = file.name.split(".").pop().toLowerCase();
            return !acceptedFileTypes.includes(`.${ext}`);
        });

        if (invalidFiles.length > 0) {
            alert(`Only the following file types are accepted: ${acceptedFilesType}`);
        } else if (files.length > filesLimit) {
            alert(`You can only upload up to ${filesLimit} file(s).`);
        } else {
            if (filesLimit === 1 && files.length === 1) {
                setUploadedFileName(files[0].name);
            }
            onChange(files);
        }
    };

    return (
        <div
            className={`${styles.container} ${dragOver ? styles.dragover : ""} ${className}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current.click()}
            {...hoverProps}
        >
            <img
                src={uploadedFileName ? uploadedDocument : isHovered ? UploadBlue : UploadBlack}
                alt=""
                className={styles.uploadIcon}
            />

            <p className={`${styles.label} ${uploadedFileName ? styles.uploaded : ""}`}>
                {dragOver
                    ? "Drop your files here"
                    : uploadedFileName
                        ? uploadedFileName
                        : label}
            </p>

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
                accept={acceptedFilesType}
                multiple={filesLimit > 1}
                {...props}
            />
        </div>
    );
};

export default FileInputDragDrop;