import styles from "./MoreInfoButton.module.css";
import Button from "../../../UI/Button/Button";
import { useState, useRef } from "react";
import { useOutsideClick } from "../../../../hooks/use-outsideClick";
import { getImageURL } from "../../../MainNavigation/SideBar/NavigationLists";

const MoreInfoButton = ({
  name,
  className,
  options = [{ name: "Dissolve", icon: "/assets/common/Prohibition.svg" }],
  onSelect,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  useOutsideClick({ ref, handler: () => setIsOpen(false) });

  const handleSelectOption = (option) => {
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className={`${styles.wrapper} ${className}`} ref={ref}>
      <Button
        className={`${styles.btn__more_info}`}
        onClick={() => setIsOpen(true)}
        {...props}
      >
        {[...Array(3).keys()].map((index) => (
          <span key={`${name}-dot-${index}`}></span>
        ))}
      </Button>
      {isOpen && (
        <div className={styles.options}>
          <ul>
            {options.map((option) => (
              <li
                key={option.name}
                onClick={() => handleSelectOption(option.name)}
              >
                <img src={getImageURL(option.icon)} alt="" />
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MoreInfoButton;
