import styles from "./EditOverlay.module.css";
import Backdrop from "../../../../../UI/Modal/Backdrop";
import Button from "../../../../../UI/Button/Button";
import closeIcon from "/assets/Close.svg";
import { complianceEditForms } from "../../../../../../consts/Constants";
import AddForeignState from "./AddForeignState";
import RemoveItem from "./RemoveItem";
import AddForeignSubsidiary from "./AddForeignSubsidiary";
import EditTaxClassification from "./EditTaxClassification";
import EditTaxPeriod from "./EditTaxPeriod";
import AddForeignSharehoder from "./AddForeignSharehoder";
import EditIssuedShares from "./EditIssuedShares";
import EditAuthorizedShares from "./EditAuthorizedShares";

const formHeaders = {
  [complianceEditForms.FOREIGN_QUALIFICATIONS]: {
    title: "Add Registered State",
    instruction: "Fill registered state details",
  },
  [complianceEditForms.FOREIGN_SUBSIDIARIES]: {
    title: "Add Foreign subsidiary",
    instruction: "Fill information to add foreign entity",
  },
  [complianceEditForms.FOREIGN_SHAREHOLDERS]: {
    title: "Add Shareholder",
    instruction:
      "Fill shareholder details",
  },
  [complianceEditForms.TAX_PERIOD]: {
    title: "Tax Period",
    instruction: "Change compliance tax filing period",
  },
  [complianceEditForms.IRS_CLASSIFICATION]: {
    title: "Change IRS classification",
    instruction: "",
  },
  [complianceEditForms.ISSUED_SHARES]: {
    title: "Enter Issued Shares",
    instruction: "Fill details to enter issued shares",
  },
  [complianceEditForms.AUTHORIZED_SHARES]: {
    title: "Enter Authorized Shares",
    instruction: "Fill details to enter authorized shares",
  },
  [complianceEditForms.DISSOLVE_STATE]: {
    title: "Dissolve State",
    instruction: "Fill details to mark state as dissolved",
    label: "Dissolution Date",
  },
  [complianceEditForms.DISSOLVE_ENTITY]: {
    title: "Dissolve Entity",
    instruction: "Fill details to mark entity as dissolved",
    label: "Dissolution Date",
  },
  [complianceEditForms.UNSUBSIDIZE_ENTITY]: {
    title: "Unsubsidize Entity",
    instruction: "Fill details to mark entity as unsubsidized",
    label: "Unsubsidization Date",
  },
  [complianceEditForms.REMOVE_SHAREHOLDER]: {
    title: "Remove Foreign Shareholder",
    instruction: "Fill details to confirm shareholder exit",
    label: "Exit Date",
  },
};

const EditOverlay = ({
  className,
  title,
  instruction,
  onClose,
  formName,
  ...props
}) => {
  let formContent = <></>;

  switch (formName) {
    case complianceEditForms.FOREIGN_QUALIFICATIONS:
      formContent = <AddForeignState {...props} onClose={onClose}/>;
      break;
    case complianceEditForms.REMOVE_SHAREHOLDER:
    case complianceEditForms.DISSOLVE_STATE:
    case complianceEditForms.UNSUBSIDIZE_ENTITY:
    case complianceEditForms.DISSOLVE_ENTITY:
      formContent = <RemoveItem label={formHeaders[formName].label} onClose={onClose}/>;
      break;
    
    case complianceEditForms.FOREIGN_SUBSIDIARIES:
      formContent = <AddForeignSubsidiary {...props} onClose={onClose}/>;
      break;
    case complianceEditForms.IRS_CLASSIFICATION:
      formContent = <EditTaxClassification {...props} onClose={onClose}/>;
      break;
    case complianceEditForms.TAX_PERIOD:
      formContent = <EditTaxPeriod {...props} onClose={onClose}/>;
      break;
    case complianceEditForms.FOREIGN_SHAREHOLDERS:
      formContent = <AddForeignSharehoder {...props} onClose={onClose}/>
      break;
    case complianceEditForms.ISSUED_SHARES:
      formContent = <EditIssuedShares {...props} onClose={onClose}/>
      break;
    case complianceEditForms.AUTHORIZED_SHARES:
      formContent = <EditAuthorizedShares {...props} onClose={onClose}/>
      break;
    default:
      formContent = <></>;
  }

  return (
    <Backdrop className={styles.backdrop}>
      <div className={`${styles.overlay} ${className}`}>
        <Button onClick={onClose} className={styles.btn__close}>
          <img src={closeIcon} alt="" />
        </Button>
        <div className={styles.header}>
          <div className={styles.title}>{formHeaders[formName].title}</div>
          <div className={styles.instruction}>
            {formHeaders[formName].instruction}
          </div>
        </div>
        <div className={styles.form__content}>{formContent}</div>
      </div>
    </Backdrop>
  );
};

export default EditOverlay;
