import styles from "./SwitchInput.module.css";
import { motion } from "framer-motion";

const SwitchButton = ({ label, active, onClick }) => {
  const isActive = active === label;

  return (
    <div
      className={`${styles.switch_button} ${isActive ? styles.selected : ""}`}
      onClick={onClick}
    >
      <div className={styles.switch_button_text}>{label}</div>
      {isActive && (
        <motion.div
          className={styles.active}
          layoutId="switch-active"
          transition={{ duration: 0.3, ease: "easeInOut" }}
        ></motion.div>
      )}
    </div>
  );
};

const SwitchInput = ({
  leftLabel,
  rightLabel,
  onChange,
  active,
  className,
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <SwitchButton
        label={leftLabel}
        active={active}
        onClick={() => onChange(leftLabel)}
      />

      <SwitchButton
        label={rightLabel}
        active={active}
        onClick={() => onChange(rightLabel)}
      />
    </div>
  );
};

export default SwitchInput;
